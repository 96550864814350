import React, { useState, useEffect } from 'react'
import D from 'src/components/AntD'
import { Form, Button, DatePicker, Space, Radio, RadioChangeEvent, Tooltip } from 'antd'
import _ from 'underscore'
import moment from 'moment'
import { InfoCircleOutlined } from '@ant-design/icons'
import { getTooltipContentOrder } from 'src/api/screenCondition'

import { CustomerMetaOrderdata } from 'src/views/taskManagement/customerMetaOrderdata'


interface Iprops {
    CustomerMetaOrderdata: CustomerMetaOrderdata
    antForm: any
    orderIndex: number
    index: number
    isDetail: boolean
    fieldIndex: number
}

const DateTimeComponent = ({ CustomerMetaOrderdata, antForm,orderIndex, index, fieldIndex, isDetail }: Iprops) => {
    const { RangePicker } = DatePicker
    const [tooltip, setTooltip] = useState('')
    const queryTooltip = async ({ uniqueId, operator, subOperator, values }: any) => {
        const data = await getTooltipContentOrder({
            uniqueId,
            operator,
            subOperator,
            values
        })
        setTooltip(data.data || '')
    }
    useEffect(() => {
        getTooltip()
    }, [CustomerMetaOrderdata])

    const getTooltip = () => {
        const attributeMetadata = CustomerMetaOrderdata.findAttributeMetadataByIndex(orderIndex, index)
        if (['RT_C_T', 'RT_C_D'].includes(attributeMetadata.operatorValue)) {
            if (attributeMetadata.extra.value) {
                queryTooltip({
                    uniqueId: attributeMetadata.uniqueId,
                    operator: attributeMetadata.operatorValue,
                    subOperator: attributeMetadata.subOperatorValue,
                    values: [attributeMetadata.extra.value]
                })
            } else if (attributeMetadata.extra.start && attributeMetadata.extra.end) {
                queryTooltip({
                    uniqueId: attributeMetadata.uniqueId,
                    operator: attributeMetadata.operatorValue,
                    subOperator: attributeMetadata.subOperatorValue,
                    values: [attributeMetadata.extra.start, attributeMetadata.extra.end]
                })
            }
        }
    }

    const getExtraComponent = () => {
        const attributeMetadata = CustomerMetaOrderdata.findAttributeMetadataByIndex(orderIndex, index)
        const operatorInfo = _.findWhere(attributeMetadata.operators || [], { operator: attributeMetadata.operatorValue })
        const subOperatorInfo = _.findWhere(operatorInfo?.subOperators || [], { subOperator: attributeMetadata.subOperatorValue })
        if (subOperatorInfo?.subOperator === 'BT') {
            if (attributeMetadata?.operatorValue === "RT_C_D") {
                return (
                    <div className='flex item-center'>
                        <D.Input value={attributeMetadata.extra && attributeMetadata.extra.start} onChange={({ target: { value } }) => {
                            const n = value.replace(/[^0-9]/, '')
                            const extra = {
                                end: attributeMetadata.extra && attributeMetadata.extra.end || '',
                                start: n ? Number(n) : ''
                            }
                            const formData = CustomerMetaOrderdata.updateExtraValue(extra,orderIndex, index).getCustomerAttributeData()
                            antForm.getFieldValue('property')[orderIndex].metadataOrderForm[index] = formData[orderIndex][index]
                            antForm.setFieldsValue({
                                property: antForm.getFieldValue('property')
                            })
                            getTooltip()
                        }} placeholder="请输入"  disabled={isDetail} style={{ width: '83px' }} />
                        <span style={{ margin: '0 10px' }}>至</span>
                        <D.Input value={attributeMetadata.extra && attributeMetadata.extra.end} onChange={({ target: { value } }) => {
                            const n = value.replace(/[^0-9]/, '')
                            const extra = {
                                start: attributeMetadata.extra && attributeMetadata.extra.start || '',
                                end: n ? Number(n) : ''
                            }
                            const formData = CustomerMetaOrderdata.updateExtraValue(extra,orderIndex, index).getCustomerAttributeData()
                            antForm.getFieldValue('property')[orderIndex].metadataOrderForm[index] = formData[orderIndex][index]
                            antForm.setFieldsValue({
                                property: antForm.getFieldValue('property')
                            })
                            getTooltip()
                        }} placeholder="请输入" disabled={isDetail}  style={{ width: '83px' }} />
                        {
                            ['RT_C_T', 'RT_C_D'].includes(attributeMetadata.operatorValue) && attributeMetadata.extra.start && (
                                <Tooltip title={tooltip}>
                                    <InfoCircleOutlined style={{cursor: 'pointer', color: '#2878ff', marginLeft: '10px'}} />
                                </Tooltip>
                            )
                        }
                    </div>
                )
            } else {
                return (
                    <div className='flex item-center'>
                        <RangePicker showTime={{ format: 'HH:mm:ss' }} value={attributeMetadata.extra && attributeMetadata.extra.start && [moment(attributeMetadata.extra.start), moment(attributeMetadata.extra.end)]} onChange={(value) => {
                            const extra = {
                                start: value && value[0]?.format('YYYY-MM-DD HH:mm:ss'),
                                end: value && value[1]?.format('YYYY-MM-DD HH:mm:ss')
                            }
                            const formData = CustomerMetaOrderdata.updateExtraValue(extra,orderIndex, index).getCustomerAttributeData()
                            antForm.getFieldValue('property')[orderIndex].metadataOrderForm[index] = formData[orderIndex][index]
                            antForm.setFieldsValue({
                                property: antForm.getFieldValue('property')
                            })
                            if (value) {
                                queryTooltip({
                                    uniqueId: attributeMetadata.uniqueId,
                                    operator: attributeMetadata.operatorValue,
                                    subOperator: attributeMetadata.subOperatorValue,
                                    values: [value[0]?.format('YYYY-MM-DD HH:mm:ss'), value && value[1]?.format('YYYY-MM-DD HH:mm:ss')]
                                })
                            }
                        }} style={{ width: 350 }}  disabled={isDetail}/>
                        {
                            ['RT_C_T', 'RT_C_D'].includes(attributeMetadata.operatorValue) && attributeMetadata.extra.start && (
                                <Tooltip title={tooltip}>
                                    <InfoCircleOutlined style={{cursor: 'pointer', color: '#2878ff', marginLeft: '10px'}} />
                                </Tooltip>
                            )
                        }
                    </div>
                )
            }
            
        } else if (attributeMetadata.operatorValue === 'AT') {
            return <DatePicker showTime value={attributeMetadata.extra && attributeMetadata.extra.value && moment(attributeMetadata.extra.value)} onChange={(value) => {
                const extra = {
                    value: value?.format('YYYY-MM-DD HH:mm:ss')
                }
                const formData = CustomerMetaOrderdata.updateExtraValue(extra,orderIndex, index).getCustomerAttributeData()
                antForm.getFieldValue('property')[orderIndex].metadataOrderForm[index] = formData[orderIndex][index]
                //  设置extraValue来使得跟extra value相同
                antForm.getFieldValue('property')[orderIndex].metadataOrderForm[index]['extraValue'] = moment(extra.value)
                antForm.setFieldsValue({
                    property: antForm.getFieldValue('property')
                })
            }}  style={{ width: 180 }} disabled={isDetail} />
        } else if (subOperatorInfo?.valueType === 'SINGLE') {
            if (operatorInfo?.operator === 'RT_C_T') {
                return (
                    <div className='flex item-center'>
                        <D.Input value={attributeMetadata.extra && attributeMetadata.extra.value} onChange={({ target: { value } }) => {
                            const extra = {
                                ...attributeMetadata.extra,
                                value
                            }
                            const formData = CustomerMetaOrderdata.updateExtraValue(extra, orderIndex, index).getCustomerAttributeData()
                            antForm.getFieldValue('property')[orderIndex].metadataOrderForm[index] = formData[orderIndex][index]
                            antForm.setFieldsValue({
                                metadataForm: antForm.getFieldValue('property')
                            })
                            queryTooltip({
                                uniqueId: attributeMetadata.uniqueId,
                                operator: attributeMetadata.operatorValue,
                                subOperator: attributeMetadata.extra?.extraSub ?  attributeMetadata.extra.extraSub : attributeMetadata.subOperatorValue,
                                values: [value]
                            })
                        }} placeholder="请输入"  disabled={isDetail} style={{ width: 100 }} />
                        <span style={{marginLeft: '12px'}}>天</span>
                        <D.Select value={attributeMetadata.extra && attributeMetadata.extra.extraSub} options={CustomerMetaOrderdata.getSubOptionLinkage(orderIndex, index)} onChange={(values) => {
                            const extra = {
                                ...attributeMetadata.extra,
                                extraSub: values
                            }
                            const formData = CustomerMetaOrderdata.updateExtraValue(extra, orderIndex, index).getCustomerAttributeData()
                            antForm.getFieldValue('property')[orderIndex].metadataOrderForm[index] = formData[orderIndex][index]
                            antForm.setFieldsValue({
                                metadataForm: antForm.getFieldValue('property')
                            })
                            queryTooltip({
                                uniqueId: attributeMetadata.uniqueId,
                                operator: attributeMetadata.operatorValue,
                                subOperator: values,
                                values: [attributeMetadata.extra.value]
                            })

                        }} allowClear={false} placeholder="请选择"  disabled={isDetail} style={{ width: 100 ,marginLeft:'8px'}} />
                        {
                            ['RT_C_T', 'RT_C_D'].includes(attributeMetadata.operatorValue) && attributeMetadata.extra.value && (
                                <Tooltip title={tooltip}>
                                    <InfoCircleOutlined style={{cursor: 'pointer', color: '#2878ff', marginLeft: '10px'}} />
                                </Tooltip>
                            )
                        }
                    </div>
                )
            }
            return (
                <div className='flex item-center'>
                    <D.Input value={attributeMetadata.extra && attributeMetadata.extra.value} style={{ width: 130 }} onChange={({ target: { value } }) => {
                        const extra = {
                            value
                        }
                        const formData = CustomerMetaOrderdata.updateExtraValue(extra,orderIndex, index).getCustomerAttributeData()
                        antForm.getFieldValue('property')[orderIndex].metadataOrderForm[index] = formData[orderIndex][index]
                        antForm.setFieldsValue({
                            property: antForm.getFieldValue('property')
                        })
                        queryTooltip({
                            uniqueId: attributeMetadata.uniqueId,
                            operator: attributeMetadata.operatorValue,
                            subOperator: attributeMetadata.subOperatorValue,
                            values: [value]
                        })
                    }} placeholder=""  disabled={isDetail} />
                    {
                        ['RT_C_T', 'RT_C_D'].includes(attributeMetadata.operatorValue) && attributeMetadata.extra.value && (
                            <Tooltip title={tooltip}>
                                <InfoCircleOutlined style={{cursor: 'pointer', color: '#2878ff', marginLeft: '10px'}} />
                            </Tooltip>
                        )
                    }
                </div>
            )
        }
        return <div />
    }

    const getSubOperatorComponent = () => {
        const attributeMetadata = CustomerMetaOrderdata.findAttributeMetadataByIndex(orderIndex, index)
        const operatorInfo = _.findWhere(attributeMetadata.operators || [], { operator: attributeMetadata.operatorValue })
        if (operatorInfo?.subOperators) {
            return (
                <D.Select options={CustomerMetaOrderdata.getSubOperatorOption(orderIndex,index)} onChange={(value: any) => {

                    if (['P_I_D', 'P_B_D', 'F_I_D', 'F_A_D'].includes(value)) {
                        const formData = CustomerMetaOrderdata.updateExtraSubValueRT(value as string, orderIndex, index).getCustomerAttributeData()
                        antForm.getFieldValue('property')[orderIndex].metadataOrderForm[index] = formData[index][index]
                        antForm.setFieldsValue({
                            metadataForm: antForm.getFieldValue('property')
                        }) 
                    } else {
                        const formData = CustomerMetaOrderdata.updateSubOperatorValueByIndex(value as string, orderIndex, index).getCustomerAttributeData()
                        antForm.getFieldValue('property')[orderIndex].metadataOrderForm[index] = formData[orderIndex][index]
                        antForm.setFieldsValue({
                            metadataForm: antForm.getFieldValue('property')
                        })
                    }

                    // const formData = CustomerMetaOrderdata.updateSubOperatorValueByIndex(value as string,orderIndex, index).getCustomerAttributeData()
                    // antForm.getFieldValue('property')[orderIndex].metadataOrderForm[index] = formData[orderIndex][index]
                    // antForm.setFieldsValue({
                    //     property: antForm.getFieldValue('property')
                    // })
    
                }} allowClear={false} placeholder="请选择" disabled={isDetail}  style={{ width:operatorInfo.operator !== 'RT_C_T' ? 150 : 80 }} />
                // <D.Select options={CustomerMetaOrderdata.getSubOperatorOption(orderIndex,index)} onChange={(value) => {
                //     const formData = CustomerMetaOrderdata.updateSubOperatorValueByIndex(value as string,orderIndex, index).getCustomerAttributeData()
                //     antForm.getFieldValue('property')[orderIndex].metadataOrderForm[index] = formData[orderIndex][index]
                //     antForm.setFieldsValue({
                //         property: antForm.getFieldValue('property')
                //     })
    
            // }} allowClear={false} placeholder="请选择" disabled={isDetail} style={{ width: 130 }} />
            )
        }
        return <div />
    }
    
    const formList = [
        {
            label: '',
            name: 'queryName',
            children: <D.Select options={CustomerMetaOrderdata.getGroupOptions()} onChange={(value) => {
                const formData = CustomerMetaOrderdata.updateQueryNameByIndex(value as string,orderIndex, index).getCustomerAttributeData()
                antForm.getFieldValue('property')[orderIndex].metadataOrderForm[index] = formData[orderIndex][index]
                antForm.setFieldsValue({
                    property: antForm.getFieldValue('property')
                })
            }} allowClear={false} placeholder="请选择" disabled={isDetail} style={{ width: 150 }} />,
        },
        {
            label: '',
            name: 'operatorValue',
            children: <D.Select options={CustomerMetaOrderdata.getOperatorOption(orderIndex,index)} onChange={(value) => {
                const formData = CustomerMetaOrderdata.updateOperatorValueByIndex(value as string,orderIndex, index).getCustomerAttributeData()
                antForm.getFieldValue('property')[orderIndex].metadataOrderForm[index] = formData[orderIndex][index]
                antForm.setFieldsValue({
                    property: antForm.getFieldValue('property')
                })
            }} allowClear={false} placeholder="请选择" disabled={isDetail} style={{ width: 150 }} />,
        },
        {
            label: '',
            name: 'subOperatorValue',
            children: getSubOperatorComponent()
        },
        {
            label: '',
            name: 'extraValue',
            children: getExtraComponent(),
        },
    ]

    return (
        formList.map((f, i) => (
            <Form.Item key={f.name} {...f} name={[fieldIndex, f.name]} />
        ))
    )
}
DateTimeComponent.indentify = 'DATETIME'

export default DateTimeComponent