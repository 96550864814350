import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'mobx-react'
import stores from 'src/store'
import { configure } from 'mobx' // 开启严格模式
// import "./assets/css/index.css"
import 'antd/dist/antd.less' // 引入antd css
import './assets/css/index.module.less'
import './assets/svgIcons/index'
import './assets/css/index.less'
import register from 'src/views/metadataComponents'
import orderRegister from 'src/views/metadataOrderComponents'
import zhCN from 'antd/lib/locale/zh_CN';
import { ConfigProvider } from 'antd';

configure({ enforceActions: 'always' }) // 开启严格模式

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
    <Provider {...stores}>
        <BrowserRouter>
            <ConfigProvider locale={zhCN}>
                <App />
            </ConfigProvider>
        </BrowserRouter>
    </Provider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
register()
orderRegister()
