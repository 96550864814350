import request from './request'
import { LoginParam, UserInfoParam, MobileMessageParam, LoginByMobileParam, MobileForgetParam, PasswordResetParam } from '../types/login';
import { AnyObject } from 'yup/lib/types';
export const loginRequest = (data: LoginParam) => {
    console.log('LoginParam', data)
    return request({
        url: '/auth-service/authorization/user/login/password',
        method: 'post',
        data,
    });
};

export const loginRequestByMobile = (data: LoginByMobileParam) => {
    return request({
        url:'/auth-service/authorization/user/login/verifyCode',
        method: 'post',
        data
    });
};

// 获取图形验证码 getCaptchaImg
export const getCaptchaImg = () => {
    return request({
        url: '/auth-service/authorization/common/captcha',
        method: 'get',
        params: {
            width: 220,
            height: 58
        }
    });
};

export const userRequest = (data: UserInfoParam) => {
    return request({
        url:'/auth-service/authorization/user/login/store', 
        data,
        method: 'post',
    })
};

export const userInfoCheck = () => {
    return request({
        url: '/auth-service/authorization/user/info/current',
        method: 'get',
        params: {}
    });
};


export const mobileMessageRequest = (data: MobileMessageParam) => {
    return request({
        url:'/auth-service/authorization/user/login/verifyCode/send',
        method: 'post',
        data
    });
};

export const validateResetSMS = (data: MobileMessageParam) => {
    return request({
        url:'/center-client/authenticate/code/3', 
        method: 'post',
        data: {
            queryName: 'validateResetSMS'
        }
    });
};

export const mobileForgetRequest = (data: MobileForgetParam) => {
    return request({
        url:'/center-client/verify/code',
        method: 'post',
        data: {
            queryName: 'mobileForgetRequest'
        }
    });
};

export const passwordResetRequest = (data: PasswordResetParam) => {
    return request({
        url:'/center-client/account/reset_passwd', 
        method: 'post',
        data: {
            queryName: 'passwordResetRequest'
        }
    });
};

export const resetPassword = (data: any) => {
    return request({
        url:'/auth-service/authorization/user/login/password/update', 
        method: 'post',
        data
    });
};



export const logout = () => {
    return request({
        url:'/auth-service/authorization/user/logout',
        method: 'post'
    });
};
