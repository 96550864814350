import { observable, action, makeAutoObservable, computed } from 'mobx';

import {

    getCustomerScreenMetadataRequest,
    customersMedataEnumRequest,
    customersDetailsHistoryOrderRequest,
    customersGroupMetaDataRequest
} from 'src/api/taskManage';


// types
import {
    Page,
    CustomersMetadata,
    CustomersMetadataEnum,
    MetadataDetailParams,
    HistoryOrderColumn,
    CustomersHistoryOrderParams,
    HistoryOrderData,
    CustomersGroupMetadata
} from '../../types/customers';


export class TaskManagerStore {
    @observable
        customersMetadata: CustomersMetadata[] = [];
    @observable
        customersEnumMetadata: CustomersMetadataEnum = {};
        // 历史工单详情表头
    @observable
        customersHistoryOrderColumns: HistoryOrderColumn[] = []; 
        // 历史工单详情表单
    @observable
        customersHistoryOrderData: HistoryOrderData[] = [];
        // 历史工单详情 分页信息
    @observable
        customersHistoryOrderPageInfo: any = {
            pageSize: 20,
        }
        // 添加用户属性条件
    @observable
        customersGroupMetaDataList: any = [];    
    constructor() {
        makeAutoObservable(this, {}, { autoBind: true })
    }

    @action
    async customersMedataEnumRequest({ queryCode }: MetadataDetailParams) {
        const response = await customersMedataEnumRequest({
            queryCode,
        });
        if (response.code === '00000') {
            this.customersEnumMetadata[queryCode] = response.data;
        }
    }
    
    @action
    async customersDetailsHistoryOrderRequest({ carId, page }: CustomersHistoryOrderParams) {
        console.log(222)
        // const response = await customersDetailsHistoryOrderRequest({
        //     carId,
        //     page,
        // });
        // if (response.code === '00000') {
        // this.customersHistoryOrderColumns = response.data.dynamicColumnInfoList;
        this.customersHistoryOrderColumns = [
            {
                "queryCode": "order_id",
                "displayName": "工单号"
            },
            {
                "queryCode": "main_repair_type_secondary_category",
                "displayName": "维修类型"
            },
            {
                "queryCode": "miles",
                "displayName": "进站里程(Km)"
            },
            {
                "queryCode": "main_total_fee",
                "displayName": "合计金额(元)"
            },
            {
                "queryCode": "main_repair_date",
                "displayName": "进站日期"
            },
            {
                "queryCode": "main_settle_date",
                "displayName": "结算日期"
            },
            {
                "queryCode": "sa_name",
                "displayName": "服务顾问"
            }
        ]
        // this.customersHistoryOrderData = response.data.repairOrderDynamicInfoList.map((item: HistoryOrderData) => {
        //     item.repairOrderResultList.forEach(k => {
        //         item[k.queryCode] = k.value;
        //     })
        //     return item
        // });
        // this.customersHistoryOrderPageInfo = response.page;
    // }
    }

    @action
    async customersColumnsMetadataRequest() {
        const response = await getCustomerScreenMetadataRequest();
        if (response.code === '00000') {
            this.customersMetadata = response.data;
        }
        console.log('this.customersMetadata', this.customersMetadata)
        // this.customersMetadata = 
        //     [
        //         {
        //             "queryCode": "main_car_id",
        //             "queryDescribe": null,
        //             "tableCode": "CUSTOMER_INFO",
        //             "name": "底盘号",
        //             "unit": null,
        //             "dataType": "STRING",
        //             "timeDirection": null,
        //             "timePattern": null,
        //             "operatorMetaDataList": [
        //                 {
        //                     "queryOperator": "EQUAL",
        //                     "defaultFlag": 1,
        //                     "subOperatorMetaDataList": [],
        //                     "keyList": []
        //                 }
        //             ]
        //         },
        //         {
        //             "queryCode": "main_plate_id",
        //             "queryDescribe": null,
        //             "tableCode": "CUSTOMER_INFO",
        //             "name": "车牌号",
        //             "unit": null,
        //             "dataType": "STRING",
        //             "timeDirection": null,
        //             "timePattern": null,
        //             "operatorMetaDataList": [
        //                 {
        //                     "queryOperator": "EQUAL",
        //                     "defaultFlag": 1,
        //                     "subOperatorMetaDataList": [],
        //                     "keyList": []
        //                 }
        //             ]
        //         },
        //         {
        //             "queryCode": "invite_mobile",
        //             "queryDescribe": null,
        //             "tableCode": "CUSTOMER_INFO",
        //             "name": "邀约人电话",
        //             "unit": null,
        //             "dataType": "STRING",
        //             "timeDirection": null,
        //             "timePattern": null,
        //             "operatorMetaDataList": [
        //                 {
        //                     "queryOperator": "EQUAL",
        //                     "defaultFlag": 1,
        //                     "subOperatorMetaDataList": [],
        //                     "keyList": []
        //                 }
        //             ]
        //         },
        //         {
        //             "queryCode": "main_car_series_name",
        //             "queryDescribe": null,
        //             "tableCode": "CUSTOMER_INFO",
        //             "name": "车系",
        //             "unit": null,
        //             "dataType": "ENUM",
        //             "timeDirection": null,
        //             "timePattern": null,
        //             "operatorMetaDataList": [
        //                 {
        //                     "queryOperator": "SINGLE_IN",
        //                     "defaultFlag": 1,
        //                     "subOperatorMetaDataList": [],
        //                     "keyList": []
        //                 }
        //             ]
        //         },
        //         {
        //             "queryCode": "customer_mobile",
        //             "queryDescribe": null,
        //             "tableCode": "CUSTOMER_INFO",
        //             "name": "车主电话",
        //             "unit": null,
        //             "dataType": "STRING",
        //             "timeDirection": null,
        //             "timePattern": null,
        //             "operatorMetaDataList": [
        //                 {
        //                     "queryOperator": "EQUAL",
        //                     "defaultFlag": 1,
        //                     "subOperatorMetaDataList": [],
        //                     "keyList": []
        //                 }
        //             ]
        //         },
        //         {
        //             "queryCode": "contact_mobile",
        //             "queryDescribe": null,
        //             "tableCode": "CUSTOMER_INFO",
        //             "name": "联系人电话",
        //             "unit": null,
        //             "dataType": "STRING",
        //             "timeDirection": null,
        //             "timePattern": null,
        //             "operatorMetaDataList": [
        //                 {
        //                     "queryOperator": "EQUAL",
        //                     "defaultFlag": 1,
        //                     "subOperatorMetaDataList": [],
        //                     "keyList": []
        //                 }
        //             ]
        //         },
        //         {
        //             "queryCode": "main_sa_name",
        //             "queryDescribe": "最后一次进站时的接待顾问",
        //             "tableCode": "CUSTOMER_INFO",
        //             "name": "上次接待服务顾问",
        //             "unit": null,
        //             "dataType": "ENUM",
        //             "timeDirection": null,
        //             "timePattern": null,
        //             "operatorMetaDataList": [
        //                 {
        //                     "queryOperator": "SELECT",
        //                     "defaultFlag": 1,
        //                     "subOperatorMetaDataList": [],
        //                     "keyList": []
        //                 }
        //             ]
        //         },
        //         {
        //             "queryCode": "car_age",
        //             "queryDescribe": null,
        //             "tableCode": "CUSTOMER_INFO",
        //             "name": "车龄",
        //             "unit": "年",
        //             "dataType": "NUMBER",
        //             "timeDirection": null,
        //             "timePattern": null,
        //             "operatorMetaDataList": [
        //                 {
        //                     "queryOperator": "BETWEEN",
        //                     "defaultFlag": 1,
        //                     "subOperatorMetaDataList": [],
        //                     "keyList": [
        //                         "START",
        //                         "END"
        //                     ]
        //                 }
        //             ]
        //         },
        //         {
        //             "queryCode": "interval_mileage_forecast",
        //             "queryDescribe": "预测日均里程数X未进站天数",
        //             "tableCode": "CUSTOMER_LABEL_DAILY",
        //             "name": "预测进站里程间隔",
        //             "unit": null,
        //             "dataType": "NUMBER",
        //             "timeDirection": null,
        //             "timePattern": null,
        //             "operatorMetaDataList": [
        //                 {
        //                     "queryOperator": "BETWEEN",
        //                     "defaultFlag": 1,
        //                     "subOperatorMetaDataList": [],
        //                     "keyList": [
        //                         "START",
        //                         "END"
        //                     ]
        //                 }
        //             ]
        //         },
        //         {
        //             "queryCode": "recency",
        //             "queryDescribe": "截至今日车辆已多少天未进站",
        //             "tableCode": "CUSTOMER_LABEL_DAILY",
        //             "name": "未进站天数",
        //             "unit": "天",
        //             "dataType": "NUMBER",
        //             "timeDirection": null,
        //             "timePattern": null,
        //             "operatorMetaDataList": [
        //                 {
        //                     "queryOperator": "BETWEEN",
        //                     "defaultFlag": 1,
        //                     "subOperatorMetaDataList": [],
        //                     "keyList": [
        //                         "START",
        //                         "END"
        //                     ]
        //                 }
        //             ]
        //         },
        //         {
        //             "queryCode": "main_settle_date",
        //             "queryDescribe": null,
        //             "tableCode": "REPAIR_ORDER",
        //             "name": "结算日期",
        //             "unit": "天",
        //             "dataType": "DATE",
        //             "timeDirection": "BEFORE",
        //             "timePattern": "yyyy-MM-dd",
        //             "operatorMetaDataList": [
        //                 {
        //                     "queryOperator": "ABSOLUTE_TIME",
        //                     "defaultFlag": 1,
        //                     "subOperatorMetaDataList": [],
        //                     "keyList": [
        //                         "START",
        //                         "END"
        //                     ]
        //                 }
        //             ]
        //         },
        //         {
        //             "queryCode": "order_part_category",
        //             "queryDescribe": null,
        //             "tableCode": "REPAIR_ORDER_PART_DETAIL",
        //             "name": "配件分类",
        //             "unit": null,
        //             "dataType": "ENUM",
        //             "timeDirection": null,
        //             "timePattern": null,
        //             "operatorMetaDataList": [
        //                 {
        //                     "queryOperator": "IN",
        //                     "defaultFlag": 1,
        //                     "subOperatorMetaDataList": [],
        //                     "keyList": []
        //                 }
        //             ]
        //         },
        //         {
        //             "queryCode": "main_total_fee",
        //             "queryDescribe": null,
        //             "tableCode": "REPAIR_ORDER",
        //             "name": "总金额",
        //             "unit": "元",
        //             "dataType": "NUMBER",
        //             "timeDirection": null,
        //             "timePattern": null,
        //             "operatorMetaDataList": [
        //                 {
        //                     "queryOperator": "BETWEEN",
        //                     "defaultFlag": 1,
        //                     "subOperatorMetaDataList": [],
        //                     "keyList": [
        //                         "START",
        //                         "END"
        //                     ]
        //                 }
        //             ]
        //         },
        //         {
        //             "queryCode": "return_decile",
        //             "queryDescribe": "预测下月进站保养可能性，Top百分比值越低，下月保养可能性越高",
        //             "tableCode": "CUSTOMER_LABEL_MONTHLY",
        //             "name": "进站概率TOP分位",
        //             "unit": null,
        //             "dataType": "PERCENT",
        //             "timeDirection": null,
        //             "timePattern": null,
        //             "operatorMetaDataList": [
        //                 {
        //                     "queryOperator": "SELECT",
        //                     "defaultFlag": 1,
        //                     "subOperatorMetaDataList": [],
        //                     "keyList": []
        //                 }
        //             ]
        //         },
        //         {
        //             "queryCode": "frequency_last1year",
        //             "queryDescribe": null,
        //             "tableCode": "CUSTOMER_LABEL_DAILY",
        //             "name": "最近1年进站次数",
        //             "unit": "次",
        //             "dataType": "NUMBER",
        //             "timeDirection": null,
        //             "timePattern": null,
        //             "operatorMetaDataList": [
        //                 {
        //                     "queryOperator": "BETWEEN",
        //                     "defaultFlag": 1,
        //                     "subOperatorMetaDataList": [],
        //                     "keyList": [
        //                         "START",
        //                         "END"
        //                     ]
        //                 }
        //             ]
        //         },
        //         {
        //             "queryCode": "churn_decile",
        //             "queryDescribe": "预测未来12个月流失可能性，Top百分比值越低，流失可能性越大",
        //             "tableCode": "CUSTOMER_LABEL_MONTHLY",
        //             "name": "流失概率TOP分位",
        //             "unit": null,
        //             "dataType": "PERCENT",
        //             "timeDirection": null,
        //             "timePattern": null,
        //             "operatorMetaDataList": [
        //                 {
        //                     "queryOperator": "SELECT",
        //                     "defaultFlag": 1,
        //                     "subOperatorMetaDataList": [],
        //                     "keyList": []
        //                 }
        //             ]
        //         },
        //         {
        //             "queryCode": "main_repair_type_secondary_category",
        //             "queryDescribe": null,
        //             "tableCode": "REPAIR_ORDER",
        //             "name": "维修类别",
        //             "unit": null,
        //             "dataType": "ENUM",
        //             "timeDirection": null,
        //             "timePattern": null,
        //             "operatorMetaDataList": [
        //                 {
        //                     "queryOperator": "IN",
        //                     "defaultFlag": 1,
        //                     "subOperatorMetaDataList": [],
        //                     "keyList": []
        //                 }
        //             ]
        //         },
        //         {
        //             "queryCode": "last_date",
        //             "queryDescribe": null,
        //             "tableCode": "CUSTOMER_LABEL_DAILY",
        //             "name": "上次进站日期",
        //             "unit": "天",
        //             "dataType": "DATE",
        //             "timeDirection": "BEFORE",
        //             "timePattern": "yyyy-MM-dd",
        //             "operatorMetaDataList": [
        //                 {
        //                     "queryOperator": "ABSOLUTE_TIME",
        //                     "defaultFlag": 1,
        //                     "subOperatorMetaDataList": [],
        //                     "keyList": [
        //                         "START",
        //                         "END"
        //                     ]
        //                 }
        //             ]
        //         }
        //     ]
        // }
    }
    // 筛选条件
    @action
    async customersGroupMetaDataRequest() {
        this.customersGroupMetaDataList = 
            [{
                "groupName": "车主资料",
                "metaDataList": [{
                    "queryCode": "main_car_id",
                    "queryDescribe": null,
                    "tableCode": "CUSTOMER_INFO",
                    "name": "底盘号",
                    "unit": null,
                    "dataType": "STRING",
                    "timeDirection": null,
                    "timePattern": null,
                    "operatorMetaDataList": [{
                        "queryOperator": "EQUAL",
                        "defaultFlag": 1,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }]
                }, {
                    "queryCode": "main_plate_id",
                    "queryDescribe": null,
                    "tableCode": "CUSTOMER_INFO",
                    "name": "车牌号",
                    "unit": null,
                    "dataType": "STRING",
                    "timeDirection": null,
                    "timePattern": null,
                    "operatorMetaDataList": [{
                        "queryOperator": "EQUAL",
                        "defaultFlag": 1,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }]
                }, {
                    "queryCode": "invite_mobile",
                    "queryDescribe": null,
                    "tableCode": "CUSTOMER_INFO",
                    "name": "邀约人电话",
                    "unit": null,
                    "dataType": "STRING",
                    "timeDirection": null,
                    "timePattern": null,
                    "operatorMetaDataList": [{
                        "queryOperator": "EQUAL",
                        "defaultFlag": 1,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }]
                }, {
                    "queryCode": "main_purchase_date",
                    "queryDescribe": null,
                    "tableCode": "CUSTOMER_INFO",
                    "name": "购车日期",
                    "unit": "天",
                    "dataType": "DATE",
                    "timeDirection": "BEFORE",
                    "timePattern": "yyyy-MM-dd",
                    "operatorMetaDataList": [{
                        "queryOperator": "ABSOLUTE_TIME",
                        "defaultFlag": 1,
                        "subOperatorMetaDataList": [],
                        "keyList": ["START", "END"]
                    }, {
                        "queryOperator": "RELATIVE_CURRENT_TIME",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [{
                            "queryOperator": "BETWEEN",
                            "defaultFlag": 1,
                            "subOperatorMetaDataList": [],
                            "keyList": ["START", "END"]
                        }, {
                            "queryOperator": "EQUAL",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "NOT_EQUAL",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "LESS",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "GREATER",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "LESS_OR_EQUAL",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "GREATER_OR_EQUAL",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }],
                        "keyList": []
                    }, {
                        "queryOperator": "RELATIVE_FIX_TIME",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [{
                            "queryOperator": "BETWEEN",
                            "defaultFlag": 1,
                            "subOperatorMetaDataList": [],
                            "keyList": ["START", "END"]
                        }, {
                            "queryOperator": "EQUAL",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "NOT_EQUAL",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "LESS",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "GREATER",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "LESS_OR_EQUAL",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "GREATER_OR_EQUAL",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }],
                        "keyList": ["FIX_TIME"]
                    }]
                }, {
                    "queryCode": "province,city,district",
                    "queryDescribe": null,
                    "tableCode": "CUSTOMER_INFO",
                    "name": "地区",
                    "unit": null,
                    "dataType": "POSITION",
                    "timeDirection": null,
                    "timePattern": null,
                    "operatorMetaDataList": [{
                        "queryOperator": "GEOGRAPHY",
                        "defaultFlag": 1,
                        "subOperatorMetaDataList": [],
                        "keyList": ["PROVINCE", "CITY", "DISTRICT"]
                    }]
                }, {
                    "queryCode": "drive_distance",
                    "queryDescribe": null,
                    "tableCode": "CUSTOMER_INFO",
                    "name": "距离4S店行驶距离",
                    "unit": "公里",
                    "dataType": "NUMBER",
                    "timeDirection": null,
                    "timePattern": null,
                    "operatorMetaDataList": [{
                        "queryOperator": "BETWEEN",
                        "defaultFlag": 1,
                        "subOperatorMetaDataList": [],
                        "keyList": ["START", "END"]
                    }, {
                        "queryOperator": "EQUAL",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "NOT_EQUAL",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "LESS",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "GREATER",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "LESS_OR_EQUAL",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "GREATER_OR_EQUAL",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }]
                }, {
                    "queryCode": "drive_duration",
                    "queryDescribe": null,
                    "tableCode": "CUSTOMER_INFO",
                    "name": "距离4S店行驶时长",
                    "unit": "分钟",
                    "dataType": "NUMBER",
                    "timeDirection": null,
                    "timePattern": null,
                    "operatorMetaDataList": [{
                        "queryOperator": "BETWEEN",
                        "defaultFlag": 1,
                        "subOperatorMetaDataList": [],
                        "keyList": ["START", "END"]
                    }, {
                        "queryOperator": "EQUAL",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "NOT_EQUAL",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "LESS",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "GREATER",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "LESS_OR_EQUAL",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "GREATER_OR_EQUAL",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }]
                }, {
                    "queryCode": "main_sa_name",
                    "queryDescribe": "最后一次进站时的接待顾问",
                    "tableCode": "CUSTOMER_INFO",
                    "name": "上次接待服务顾问",
                    "unit": null,
                    "dataType": "ENUM",
                    "timeDirection": null,
                    "timePattern": null,
                    "operatorMetaDataList": [{
                        "queryOperator": "SINGLE_IN",
                        "defaultFlag": 1,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "SINGLE_NOT_IN",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }]
                }, {
                    "queryCode": "car_age",
                    "queryDescribe": null,
                    "tableCode": "CUSTOMER_INFO",
                    "name": "车龄",
                    "unit": "年",
                    "dataType": "NUMBER",
                    "timeDirection": null,
                    "timePattern": null,
                    "operatorMetaDataList": [{
                        "queryOperator": "BETWEEN",
                        "defaultFlag": 1,
                        "subOperatorMetaDataList": [],
                        "keyList": ["START", "END"]
                    }, {
                        "queryOperator": "EQUAL",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "NOT_EQUAL",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "LESS",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "GREATER",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "LESS_OR_EQUAL",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "GREATER_OR_EQUAL",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }]
                }, {
                    "queryCode": "main_car_model_name",
                    "queryDescribe": null,
                    "tableCode": "CUSTOMER_INFO",
                    "name": "车型",
                    "unit": null,
                    "dataType": "ENUM",
                    "timeDirection": null,
                    "timePattern": null,
                    "operatorMetaDataList": [{
                        "queryOperator": "SINGLE_IN",
                        "defaultFlag": 1,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "SINGLE_NOT_IN",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }]
                }, {
                    "queryCode": "main_car_series_name",
                    "queryDescribe": null,
                    "tableCode": "CUSTOMER_INFO",
                    "name": "车系",
                    "unit": null,
                    "dataType": "ENUM",
                    "timeDirection": null,
                    "timePattern": null,
                    "operatorMetaDataList": [{
                        "queryOperator": "SINGLE_IN",
                        "defaultFlag": 1,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "SINGLE_NOT_IN",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }]
                }, {
                    "queryCode": "is_own_sales",
                    "queryDescribe": "客户车辆是否从本公司购买",
                    "tableCode": "CUSTOMER_INFO",
                    "name": "本公司售车",
                    "unit": null,
                    "dataType": "BOOLEAN",
                    "timeDirection": null,
                    "timePattern": null,
                    "operatorMetaDataList": [{
                        "queryOperator": "EQUAL",
                        "defaultFlag": 1,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }]
                }, {
                    "queryCode": "is_own_assurance",
                    "queryDescribe": "客户保险是否从本公司购买",
                    "tableCode": "CUSTOMER_INFO",
                    "name": "本公司保险",
                    "unit": null,
                    "dataType": "BOOLEAN",
                    "timeDirection": null,
                    "timePattern": null,
                    "operatorMetaDataList": [{
                        "queryOperator": "EQUAL",
                        "defaultFlag": 1,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }]
                }, {
                    "queryCode": "insurance_day",
                    "queryDescribe": null,
                    "tableCode": "CUSTOMER_INFO",
                    "name": "保险到期日",
                    "unit": "天",
                    "dataType": "PERIOD_DATE",
                    "timeDirection": "AFTER",
                    "timePattern": "MM-dd",
                    "operatorMetaDataList": [{
                        "queryOperator": "RELATIVE_CURRENT_TIME",
                        "defaultFlag": 1,
                        "subOperatorMetaDataList": [{
                            "queryOperator": "BETWEEN",
                            "defaultFlag": 1,
                            "subOperatorMetaDataList": [],
                            "keyList": ["START", "END"]
                        }, {
                            "queryOperator": "EQUAL",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "NOT_EQUAL",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "LESS",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "GREATER",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "LESS_OR_EQUAL",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "GREATER_OR_EQUAL",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }],
                        "keyList": []
                    }]
                }, {
                    "queryCode": "car_owner_type",
                    "queryDescribe": "车辆属于私人或企业等",
                    "tableCode": "CUSTOMER_INFO",
                    "name": "车辆归属",
                    "unit": null,
                    "dataType": "ENUM",
                    "timeDirection": null,
                    "timePattern": null,
                    "operatorMetaDataList": [{
                        "queryOperator": "SINGLE_IN",
                        "defaultFlag": 1,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "SINGLE_NOT_IN",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }]
                }, {
                    "queryCode": "birthday",
                    "queryDescribe": null,
                    "tableCode": "CUSTOMER_INFO",
                    "name": "车主生日",
                    "unit": "天",
                    "dataType": "PERIOD_DATE",
                    "timeDirection": "AFTER",
                    "timePattern": "MM-dd",
                    "operatorMetaDataList": [{
                        "queryOperator": "RELATIVE_CURRENT_TIME",
                        "defaultFlag": 1,
                        "subOperatorMetaDataList": [{
                            "queryOperator": "BETWEEN",
                            "defaultFlag": 1,
                            "subOperatorMetaDataList": [],
                            "keyList": ["START", "END"]
                        }, {
                            "queryOperator": "EQUAL",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "NOT_EQUAL",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "LESS",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "GREATER",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "LESS_OR_EQUAL",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "GREATER_OR_EQUAL",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }],
                        "keyList": []
                    }]
                }, {
                    "queryCode": "customer_label_id",
                    "queryDescribe": null,
                    "tableCode": "CUSTOMER_LABEL",
                    "name": "车主标签",
                    "unit": null,
                    "dataType": "ENUM",
                    "timeDirection": null,
                    "timePattern": null,
                    "operatorMetaDataList": [{
                        "queryOperator": "IN",
                        "defaultFlag": 1,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "ONLY_HAVE",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "ALL_HAVE",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }]
                }, {
                    "queryCode": "invite_name",
                    "queryDescribe": null,
                    "tableCode": "CUSTOMER_INFO",
                    "name": "车主姓名",
                    "unit": null,
                    "dataType": "STRING",
                    "timeDirection": null,
                    "timePattern": null,
                    "operatorMetaDataList": [{
                        "queryOperator": "LIKE",
                        "defaultFlag": 1,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }]
                }, {
                    "queryCode": "external_user_id",
                    "queryDescribe": null,
                    "tableCode": "ENTERPRISE_WECHAT_CUSTOMER",
                    "name": "是否绑定企业微信",
                    "unit": null,
                    "dataType": "STRING",
                    "timeDirection": null,
                    "timePattern": null,
                    "operatorMetaDataList": [{
                        "queryOperator": "IS_NULL",
                        "defaultFlag": 1,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "IS_NOT_NULL",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }]
                }]
            }, {
                "groupName": "模型标签",
                "metaDataList": [{
                    "queryCode": "mileage_forecast",
                    "queryDescribe": "预测当前的总里程数",
                    "tableCode": "CUSTOMER_LABEL_DAILY",
                    "name": "预测里程",
                    "unit": "公里",
                    "dataType": "NUMBER",
                    "timeDirection": null,
                    "timePattern": null,
                    "operatorMetaDataList": [{
                        "queryOperator": "BETWEEN",
                        "defaultFlag": 1,
                        "subOperatorMetaDataList": [],
                        "keyList": ["START", "END"]
                    }, {
                        "queryOperator": "EQUAL",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "NOT_EQUAL",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "LESS",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "GREATER",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "LESS_OR_EQUAL",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "GREATER_OR_EQUAL",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }]
                }, {
                    "queryCode": "interval_mileage_forecast",
                    "queryDescribe": "预测日均里程数X未进站天数",
                    "tableCode": "CUSTOMER_LABEL_DAILY",
                    "name": "预测进站里程间隔",
                    "unit": null,
                    "dataType": "NUMBER",
                    "timeDirection": null,
                    "timePattern": null,
                    "operatorMetaDataList": [{
                        "queryOperator": "BETWEEN",
                        "defaultFlag": 1,
                        "subOperatorMetaDataList": [],
                        "keyList": ["START", "END"]
                    }, {
                        "queryOperator": "EQUAL",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "NOT_EQUAL",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "LESS",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "GREATER",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "LESS_OR_EQUAL",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "GREATER_OR_EQUAL",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }]
                }, {
                    "queryCode": "interval_mileage_forecast_maint",
                    "queryDescribe": "预测日均里程数X未保养天数",
                    "tableCode": "CUSTOMER_LABEL_DAILY",
                    "name": "预测保养进站里程间隔",
                    "unit": null,
                    "dataType": "NUMBER",
                    "timeDirection": null,
                    "timePattern": null,
                    "operatorMetaDataList": [{
                        "queryOperator": "BETWEEN",
                        "defaultFlag": 1,
                        "subOperatorMetaDataList": [],
                        "keyList": ["START", "END"]
                    }, {
                        "queryOperator": "EQUAL",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "NOT_EQUAL",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "LESS",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "GREATER",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "LESS_OR_EQUAL",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "GREATER_OR_EQUAL",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }]
                }, {
                    "queryCode": "mileage_daily_avg_forecast",
                    "queryDescribe": "预估当前最新的日均里程数",
                    "tableCode": "CUSTOMER_LABEL_DAILY",
                    "name": "预测日均里程",
                    "unit": "公里",
                    "dataType": "NUMBER",
                    "timeDirection": null,
                    "timePattern": null,
                    "operatorMetaDataList": [{
                        "queryOperator": "BETWEEN",
                        "defaultFlag": 1,
                        "subOperatorMetaDataList": [],
                        "keyList": ["START", "END"]
                    }, {
                        "queryOperator": "EQUAL",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "NOT_EQUAL",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "LESS",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "GREATER",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "LESS_OR_EQUAL",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "GREATER_OR_EQUAL",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }]
                }, {
                    "queryCode": "return_decile",
                    "queryDescribe": "预测下月进站保养可能性，Top百分比值越低，下月保养可能性越高",
                    "tableCode": "CUSTOMER_LABEL_MONTHLY",
                    "name": "进站概率TOP分位",
                    "unit": null,
                    "dataType": "PERCENT",
                    "timeDirection": null,
                    "timePattern": null,
                    "operatorMetaDataList": [{
                        "queryOperator": "SELECT",
                        "defaultFlag": 1,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }]
                }, {
                    "queryCode": "churn_decile",
                    "queryDescribe": "预测未来12个月流失可能性，Top百分比值越低，流失可能性越大",
                    "tableCode": "CUSTOMER_LABEL_MONTHLY",
                    "name": "流失概率TOP分位",
                    "unit": null,
                    "dataType": "PERCENT",
                    "timeDirection": null,
                    "timePattern": null,
                    "operatorMetaDataList": [{
                        "queryOperator": "SELECT",
                        "defaultFlag": 1,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }]
                }, {
                    "queryCode": "highvalue_decile",
                    "queryDescribe": "成为或保持高价值客户的概率",
                    "tableCode": "CUSTOMER_LABEL_MONTHLY",
                    "name": "高价值概率TOP分位",
                    "unit": null,
                    "dataType": "PERCENT",
                    "timeDirection": null,
                    "timePattern": null,
                    "operatorMetaDataList": [{
                        "queryOperator": "SELECT",
                        "defaultFlag": 1,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }]
                }, {
                    "queryCode": "value_tier",
                    "queryDescribe": "用最近2年的保养次数、最近2年的保养总金额、车龄来计算客户的等级，数字越大价值越高。",
                    "tableCode": "CUSTOMER_LABEL_MONTHLY",
                    "name": "价值等级",
                    "unit": null,
                    "dataType": "ENUM",
                    "timeDirection": null,
                    "timePattern": null,
                    "operatorMetaDataList": [{
                        "queryOperator": "SINGLE_IN",
                        "defaultFlag": 1,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "SINGLE_NOT_IN",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }]
                }, {
                    "queryCode": "first_maint_pred_date",
                    "queryDescribe": "自购车日期起，按每3000公里推算",
                    "tableCode": "CUSTOMER_LABEL_DAILY",
                    "name": "首保预计时间",
                    "unit": "天",
                    "dataType": "DATE",
                    "timeDirection": "AFTER",
                    "timePattern": "yyyy-MM-dd",
                    "operatorMetaDataList": [{
                        "queryOperator": "ABSOLUTE_TIME",
                        "defaultFlag": 1,
                        "subOperatorMetaDataList": [],
                        "keyList": ["START", "END"]
                    }, {
                        "queryOperator": "RELATIVE_CURRENT_TIME",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [{
                            "queryOperator": "BETWEEN",
                            "defaultFlag": 1,
                            "subOperatorMetaDataList": [],
                            "keyList": ["START", "END"]
                        }, {
                            "queryOperator": "EQUAL",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "NOT_EQUAL",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "LESS",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "GREATER",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "LESS_OR_EQUAL",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "GREATER_OR_EQUAL",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }],
                        "keyList": []
                    }, {
                        "queryOperator": "RELATIVE_FIX_TIME",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [{
                            "queryOperator": "BETWEEN",
                            "defaultFlag": 1,
                            "subOperatorMetaDataList": [],
                            "keyList": ["START", "END"]
                        }, {
                            "queryOperator": "EQUAL",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "NOT_EQUAL",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "LESS",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "GREATER",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "LESS_OR_EQUAL",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "GREATER_OR_EQUAL",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }],
                        "keyList": ["FIX_TIME"]
                    }]
                }, {
                    "queryCode": "next_maint_pred_date",
                    "queryDescribe": "按每5000公里一次推算出下次保养日期",
                    "tableCode": "CUSTOMER_LABEL_DAILY",
                    "name": "下次预计保养时间",
                    "unit": "天",
                    "dataType": "DATE",
                    "timeDirection": "AFTER",
                    "timePattern": "yyyy-MM-dd",
                    "operatorMetaDataList": [{
                        "queryOperator": "ABSOLUTE_TIME",
                        "defaultFlag": 1,
                        "subOperatorMetaDataList": [],
                        "keyList": ["START", "END"]
                    }, {
                        "queryOperator": "RELATIVE_CURRENT_TIME",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [{
                            "queryOperator": "BETWEEN",
                            "defaultFlag": 1,
                            "subOperatorMetaDataList": [],
                            "keyList": ["START", "END"]
                        }, {
                            "queryOperator": "EQUAL",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "NOT_EQUAL",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "LESS",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "GREATER",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "LESS_OR_EQUAL",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "GREATER_OR_EQUAL",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }],
                        "keyList": []
                    }, {
                        "queryOperator": "RELATIVE_FIX_TIME",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [{
                            "queryOperator": "BETWEEN",
                            "defaultFlag": 1,
                            "subOperatorMetaDataList": [],
                            "keyList": ["START", "END"]
                        }, {
                            "queryOperator": "EQUAL",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "NOT_EQUAL",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "LESS",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "GREATER",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "LESS_OR_EQUAL",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "GREATER_OR_EQUAL",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }],
                        "keyList": ["FIX_TIME"]
                    }]
                }, {
                    "queryCode": "second_maint_pred_date",
                    "queryDescribe": "自首保起，按每5000公里推算",
                    "tableCode": "CUSTOMER_LABEL_DAILY",
                    "name": "二保预计时间",
                    "unit": "天",
                    "dataType": "DATE",
                    "timeDirection": "AFTER",
                    "timePattern": "yyyy-MM-dd",
                    "operatorMetaDataList": [{
                        "queryOperator": "ABSOLUTE_TIME",
                        "defaultFlag": 1,
                        "subOperatorMetaDataList": [],
                        "keyList": ["START", "END"]
                    }, {
                        "queryOperator": "RELATIVE_CURRENT_TIME",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [{
                            "queryOperator": "BETWEEN",
                            "defaultFlag": 1,
                            "subOperatorMetaDataList": [],
                            "keyList": ["START", "END"]
                        }, {
                            "queryOperator": "EQUAL",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "NOT_EQUAL",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "LESS",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "GREATER",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "LESS_OR_EQUAL",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "GREATER_OR_EQUAL",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }],
                        "keyList": []
                    }, {
                        "queryOperator": "RELATIVE_FIX_TIME",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [{
                            "queryOperator": "BETWEEN",
                            "defaultFlag": 1,
                            "subOperatorMetaDataList": [],
                            "keyList": ["START", "END"]
                        }, {
                            "queryOperator": "EQUAL",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "NOT_EQUAL",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "LESS",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "GREATER",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "LESS_OR_EQUAL",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "GREATER_OR_EQUAL",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }],
                        "keyList": ["FIX_TIME"]
                    }]
                }, {
                    "queryCode": "first_paymaint_pred_date",
                    "queryDescribe": "自最后一次免费保养起，按每5000公里推算",
                    "tableCode": "CUSTOMER_LABEL_DAILY",
                    "name": "首次付费保养预计时间",
                    "unit": "天",
                    "dataType": "DATE",
                    "timeDirection": "AFTER",
                    "timePattern": "yyyy-MM-dd",
                    "operatorMetaDataList": [{
                        "queryOperator": "ABSOLUTE_TIME",
                        "defaultFlag": 1,
                        "subOperatorMetaDataList": [],
                        "keyList": ["START", "END"]
                    }, {
                        "queryOperator": "RELATIVE_CURRENT_TIME",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [{
                            "queryOperator": "BETWEEN",
                            "defaultFlag": 1,
                            "subOperatorMetaDataList": [],
                            "keyList": ["START", "END"]
                        }, {
                            "queryOperator": "EQUAL",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "NOT_EQUAL",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "LESS",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "GREATER",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "LESS_OR_EQUAL",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "GREATER_OR_EQUAL",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }],
                        "keyList": []
                    }, {
                        "queryOperator": "RELATIVE_FIX_TIME",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [{
                            "queryOperator": "BETWEEN",
                            "defaultFlag": 1,
                            "subOperatorMetaDataList": [],
                            "keyList": ["START", "END"]
                        }, {
                            "queryOperator": "EQUAL",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "NOT_EQUAL",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "LESS",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "GREATER",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "LESS_OR_EQUAL",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "GREATER_OR_EQUAL",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }],
                        "keyList": ["FIX_TIME"]
                    }]
                }, {
                    "queryCode": "warranty_pred_date",
                    "queryDescribe": null,
                    "tableCode": "CUSTOMER_LABEL_DAILY",
                    "name": "质保到期预计时间",
                    "unit": "天",
                    "dataType": "DATE",
                    "timeDirection": "AFTER",
                    "timePattern": "yyyy-MM-dd",
                    "operatorMetaDataList": [{
                        "queryOperator": "ABSOLUTE_TIME",
                        "defaultFlag": 1,
                        "subOperatorMetaDataList": [],
                        "keyList": ["START", "END"]
                    }, {
                        "queryOperator": "RELATIVE_CURRENT_TIME",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [{
                            "queryOperator": "BETWEEN",
                            "defaultFlag": 1,
                            "subOperatorMetaDataList": [],
                            "keyList": ["START", "END"]
                        }, {
                            "queryOperator": "EQUAL",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "NOT_EQUAL",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "LESS",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "GREATER",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "LESS_OR_EQUAL",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "GREATER_OR_EQUAL",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }],
                        "keyList": []
                    }, {
                        "queryOperator": "RELATIVE_FIX_TIME",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [{
                            "queryOperator": "BETWEEN",
                            "defaultFlag": 1,
                            "subOperatorMetaDataList": [],
                            "keyList": ["START", "END"]
                        }, {
                            "queryOperator": "EQUAL",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "NOT_EQUAL",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "LESS",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "GREATER",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "LESS_OR_EQUAL",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "GREATER_OR_EQUAL",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }],
                        "keyList": ["FIX_TIME"]
                    }]
                }, {
                    "queryCode": "customer_frequency_value",
                    "queryDescribe": null,
                    "tableCode": "CUSTOMER_LABEL_DAILY",
                    "name": "客户价值频次类型",
                    "unit": null,
                    "dataType": "ENUM",
                    "timeDirection": null,
                    "timePattern": null,
                    "operatorMetaDataList": [{
                        "queryOperator": "SINGLE_IN",
                        "defaultFlag": 1,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "SINGLE_NOT_IN",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }]
                }, {
                    "queryCode": "contract_info",
                    "queryDescribe": null,
                    "tableCode": "CUSTOMER_LABEL_DAILY",
                    "name": "续保线索",
                    "unit": null,
                    "dataType": "ENUM",
                    "timeDirection": null,
                    "timePattern": null,
                    "operatorMetaDataList": [{
                        "queryOperator": "SINGLE_IN",
                        "defaultFlag": 1,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "SINGLE_NOT_IN",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }]
                }, {
                    "queryCode": "increase_prob",
                    "queryDescribe": null,
                    "tableCode": "CUSTOMER_LABEL_MONTHLY",
                    "name": "换购可能性",
                    "unit": null,
                    "dataType": "ENUM",
                    "timeDirection": null,
                    "timePattern": null,
                    "operatorMetaDataList": [{
                        "queryOperator": "SINGLE_IN",
                        "defaultFlag": 1,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "SINGLE_NOT_IN",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }]
                }]
            }, {
                "groupName": "工单行为",
                "metaDataList": [{
                    "queryCode": "recency",
                    "queryDescribe": "截至今日车辆已多少天未进站",
                    "tableCode": "CUSTOMER_LABEL_DAILY",
                    "name": "未进站天数",
                    "unit": "天",
                    "dataType": "NUMBER",
                    "timeDirection": null,
                    "timePattern": null,
                    "operatorMetaDataList": [{
                        "queryOperator": "BETWEEN",
                        "defaultFlag": 1,
                        "subOperatorMetaDataList": [],
                        "keyList": ["START", "END"]
                    }, {
                        "queryOperator": "EQUAL",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "NOT_EQUAL",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "LESS",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "GREATER",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "LESS_OR_EQUAL",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "GREATER_OR_EQUAL",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }]
                }, {
                    "queryCode": "recency_maint",
                    "queryDescribe": "截至今日车辆已多少天未进站保养",
                    "tableCode": "CUSTOMER_LABEL_DAILY",
                    "name": "未保养天数",
                    "unit": "天",
                    "dataType": "NUMBER",
                    "timeDirection": null,
                    "timePattern": null,
                    "operatorMetaDataList": [{
                        "queryOperator": "BETWEEN",
                        "defaultFlag": 1,
                        "subOperatorMetaDataList": [],
                        "keyList": ["START", "END"]
                    }, {
                        "queryOperator": "EQUAL",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "NOT_EQUAL",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "LESS",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "GREATER",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "LESS_OR_EQUAL",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "GREATER_OR_EQUAL",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }]
                }, {
                    "queryCode": "recency_accident",
                    "queryDescribe": "最后一次事故维修距离今日的天数",
                    "tableCode": "CUSTOMER_LABEL_DAILY",
                    "name": "最后一次事故维修时间间隔",
                    "unit": "天",
                    "dataType": "NUMBER",
                    "timeDirection": null,
                    "timePattern": null,
                    "operatorMetaDataList": [{
                        "queryOperator": "BETWEEN",
                        "defaultFlag": 1,
                        "subOperatorMetaDataList": [],
                        "keyList": ["START", "END"]
                    }, {
                        "queryOperator": "EQUAL",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "NOT_EQUAL",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "LESS",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "GREATER",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "LESS_OR_EQUAL",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "GREATER_OR_EQUAL",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }]
                }, {
                    "queryCode": "frequency_last1year",
                    "queryDescribe": null,
                    "tableCode": "CUSTOMER_LABEL_DAILY",
                    "name": "最近1年进站次数",
                    "unit": "次",
                    "dataType": "NUMBER",
                    "timeDirection": null,
                    "timePattern": null,
                    "operatorMetaDataList": [{
                        "queryOperator": "BETWEEN",
                        "defaultFlag": 1,
                        "subOperatorMetaDataList": [],
                        "keyList": ["START", "END"]
                    }, {
                        "queryOperator": "EQUAL",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "NOT_EQUAL",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "LESS",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "GREATER",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "LESS_OR_EQUAL",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "GREATER_OR_EQUAL",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }]
                }, {
                    "queryCode": "frequency_last1year_maint",
                    "queryDescribe": null,
                    "tableCode": "CUSTOMER_LABEL_DAILY",
                    "name": "最近1年保养次数",
                    "unit": "次",
                    "dataType": "NUMBER",
                    "timeDirection": null,
                    "timePattern": null,
                    "operatorMetaDataList": [{
                        "queryOperator": "BETWEEN",
                        "defaultFlag": 1,
                        "subOperatorMetaDataList": [],
                        "keyList": ["START", "END"]
                    }, {
                        "queryOperator": "EQUAL",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "NOT_EQUAL",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "LESS",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "GREATER",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "LESS_OR_EQUAL",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "GREATER_OR_EQUAL",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }]
                }, {
                    "queryCode": "monetary_last1year",
                    "queryDescribe": null,
                    "tableCode": "CUSTOMER_LABEL_DAILY",
                    "name": "最近1年消费金额",
                    "unit": "元",
                    "dataType": "NUMBER",
                    "timeDirection": null,
                    "timePattern": null,
                    "operatorMetaDataList": [{
                        "queryOperator": "BETWEEN",
                        "defaultFlag": 1,
                        "subOperatorMetaDataList": [],
                        "keyList": ["START", "END"]
                    }, {
                        "queryOperator": "EQUAL",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "NOT_EQUAL",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "LESS",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "GREATER",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "LESS_OR_EQUAL",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "GREATER_OR_EQUAL",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }]
                }, {
                    "queryCode": "monetary_last1year_maint",
                    "queryDescribe": null,
                    "tableCode": "CUSTOMER_LABEL_DAILY",
                    "name": "最近1年保养金额",
                    "unit": "元",
                    "dataType": "NUMBER",
                    "timeDirection": null,
                    "timePattern": null,
                    "operatorMetaDataList": [{
                        "queryOperator": "BETWEEN",
                        "defaultFlag": 1,
                        "subOperatorMetaDataList": [],
                        "keyList": ["START", "END"]
                    }, {
                        "queryOperator": "EQUAL",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "NOT_EQUAL",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "LESS",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "GREATER",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "LESS_OR_EQUAL",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "GREATER_OR_EQUAL",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }]
                }, {
                    "queryCode": "avg_money_last1year",
                    "queryDescribe": null,
                    "tableCode": "CUSTOMER_LABEL_DAILY",
                    "name": "最近1年单次平均消费金额",
                    "unit": "元",
                    "dataType": "NUMBER",
                    "timeDirection": null,
                    "timePattern": null,
                    "operatorMetaDataList": [{
                        "queryOperator": "BETWEEN",
                        "defaultFlag": 1,
                        "subOperatorMetaDataList": [],
                        "keyList": ["START", "END"]
                    }, {
                        "queryOperator": "EQUAL",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "NOT_EQUAL",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "LESS",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "GREATER",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "LESS_OR_EQUAL",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "GREATER_OR_EQUAL",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }]
                }, {
                    "queryCode": "avg_money_last1year_maint",
                    "queryDescribe": null,
                    "tableCode": "CUSTOMER_LABEL_DAILY",
                    "name": "最近1年单次平均保养金额",
                    "unit": "元",
                    "dataType": "NUMBER",
                    "timeDirection": null,
                    "timePattern": null,
                    "operatorMetaDataList": [{
                        "queryOperator": "BETWEEN",
                        "defaultFlag": 1,
                        "subOperatorMetaDataList": [],
                        "keyList": ["START", "END"]
                    }, {
                        "queryOperator": "EQUAL",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "NOT_EQUAL",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "LESS",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "GREATER",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "LESS_OR_EQUAL",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "GREATER_OR_EQUAL",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }]
                }, {
                    "queryCode": "frequency_last3years",
                    "queryDescribe": null,
                    "tableCode": "CUSTOMER_LABEL_DAILY",
                    "name": "最近3年进站次数",
                    "unit": "次",
                    "dataType": "NUMBER",
                    "timeDirection": null,
                    "timePattern": null,
                    "operatorMetaDataList": [{
                        "queryOperator": "BETWEEN",
                        "defaultFlag": 1,
                        "subOperatorMetaDataList": [],
                        "keyList": ["START", "END"]
                    }, {
                        "queryOperator": "EQUAL",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "NOT_EQUAL",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "LESS",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "GREATER",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "LESS_OR_EQUAL",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "GREATER_OR_EQUAL",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }]
                }, {
                    "queryCode": "frequency_last3years_maint",
                    "queryDescribe": null,
                    "tableCode": "CUSTOMER_LABEL_DAILY",
                    "name": "最近3年保养次数",
                    "unit": "次",
                    "dataType": "NUMBER",
                    "timeDirection": null,
                    "timePattern": null,
                    "operatorMetaDataList": [{
                        "queryOperator": "BETWEEN",
                        "defaultFlag": 1,
                        "subOperatorMetaDataList": [],
                        "keyList": ["START", "END"]
                    }, {
                        "queryOperator": "EQUAL",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "NOT_EQUAL",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "LESS",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "GREATER",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "LESS_OR_EQUAL",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "GREATER_OR_EQUAL",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }]
                }, {
                    "queryCode": "monetary_last3years",
                    "queryDescribe": null,
                    "tableCode": "CUSTOMER_LABEL_DAILY",
                    "name": "最近3年消费金额",
                    "unit": "元",
                    "dataType": "NUMBER",
                    "timeDirection": null,
                    "timePattern": null,
                    "operatorMetaDataList": [{
                        "queryOperator": "BETWEEN",
                        "defaultFlag": 1,
                        "subOperatorMetaDataList": [],
                        "keyList": ["START", "END"]
                    }, {
                        "queryOperator": "EQUAL",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "NOT_EQUAL",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "LESS",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "GREATER",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "LESS_OR_EQUAL",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "GREATER_OR_EQUAL",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }]
                }, {
                    "queryCode": "monetary_last3years_maint",
                    "queryDescribe": null,
                    "tableCode": "CUSTOMER_LABEL_DAILY",
                    "name": "最近3年保养金额",
                    "unit": "元",
                    "dataType": "NUMBER",
                    "timeDirection": null,
                    "timePattern": null,
                    "operatorMetaDataList": [{
                        "queryOperator": "BETWEEN",
                        "defaultFlag": 1,
                        "subOperatorMetaDataList": [],
                        "keyList": ["START", "END"]
                    }, {
                        "queryOperator": "EQUAL",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "NOT_EQUAL",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "LESS",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "GREATER",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "LESS_OR_EQUAL",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "GREATER_OR_EQUAL",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }]
                }, {
                    "queryCode": "avg_money_last3years",
                    "queryDescribe": null,
                    "tableCode": "CUSTOMER_LABEL_DAILY",
                    "name": "最近3年单次平均消费金额",
                    "unit": "元",
                    "dataType": "NUMBER",
                    "timeDirection": null,
                    "timePattern": null,
                    "operatorMetaDataList": [{
                        "queryOperator": "BETWEEN",
                        "defaultFlag": 1,
                        "subOperatorMetaDataList": [],
                        "keyList": ["START", "END"]
                    }, {
                        "queryOperator": "EQUAL",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "NOT_EQUAL",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "LESS",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "GREATER",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "LESS_OR_EQUAL",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "GREATER_OR_EQUAL",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }]
                }, {
                    "queryCode": "avg_money_last3years_maint",
                    "queryDescribe": null,
                    "tableCode": "CUSTOMER_LABEL_DAILY",
                    "name": "最近3年单次平均保养金额",
                    "unit": "元",
                    "dataType": "NUMBER",
                    "timeDirection": null,
                    "timePattern": null,
                    "operatorMetaDataList": [{
                        "queryOperator": "BETWEEN",
                        "defaultFlag": 1,
                        "subOperatorMetaDataList": [],
                        "keyList": ["START", "END"]
                    }, {
                        "queryOperator": "EQUAL",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "NOT_EQUAL",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "LESS",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "GREATER",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "LESS_OR_EQUAL",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "GREATER_OR_EQUAL",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }]
                }, {
                    "queryCode": "last_date",
                    "queryDescribe": null,
                    "tableCode": "CUSTOMER_LABEL_DAILY",
                    "name": "上次进站日期",
                    "unit": "天",
                    "dataType": "DATE",
                    "timeDirection": "BEFORE",
                    "timePattern": "yyyy-MM-dd",
                    "operatorMetaDataList": [{
                        "queryOperator": "ABSOLUTE_TIME",
                        "defaultFlag": 1,
                        "subOperatorMetaDataList": [],
                        "keyList": ["START", "END"]
                    }, {
                        "queryOperator": "RELATIVE_CURRENT_TIME",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [{
                            "queryOperator": "BETWEEN",
                            "defaultFlag": 1,
                            "subOperatorMetaDataList": [],
                            "keyList": ["START", "END"]
                        }, {
                            "queryOperator": "EQUAL",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "NOT_EQUAL",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "LESS",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "GREATER",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "LESS_OR_EQUAL",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "GREATER_OR_EQUAL",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }],
                        "keyList": []
                    }, {
                        "queryOperator": "RELATIVE_FIX_TIME",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [{
                            "queryOperator": "BETWEEN",
                            "defaultFlag": 1,
                            "subOperatorMetaDataList": [],
                            "keyList": ["START", "END"]
                        }, {
                            "queryOperator": "EQUAL",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "NOT_EQUAL",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "LESS",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "GREATER",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "LESS_OR_EQUAL",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "GREATER_OR_EQUAL",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }],
                        "keyList": ["FIX_TIME"]
                    }]
                }, {
                    "queryCode": "last_date_maint",
                    "queryDescribe": null,
                    "tableCode": "CUSTOMER_LABEL_DAILY",
                    "name": "上次保养日期",
                    "unit": "天",
                    "dataType": "DATE",
                    "timeDirection": "BEFORE",
                    "timePattern": "yyyy-MM-dd",
                    "operatorMetaDataList": [{
                        "queryOperator": "ABSOLUTE_TIME",
                        "defaultFlag": 1,
                        "subOperatorMetaDataList": [],
                        "keyList": ["START", "END"]
                    }, {
                        "queryOperator": "RELATIVE_CURRENT_TIME",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [{
                            "queryOperator": "BETWEEN",
                            "defaultFlag": 1,
                            "subOperatorMetaDataList": [],
                            "keyList": ["START", "END"]
                        }, {
                            "queryOperator": "EQUAL",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "NOT_EQUAL",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "LESS",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "GREATER",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "LESS_OR_EQUAL",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "GREATER_OR_EQUAL",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }],
                        "keyList": []
                    }, {
                        "queryOperator": "RELATIVE_FIX_TIME",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [{
                            "queryOperator": "BETWEEN",
                            "defaultFlag": 1,
                            "subOperatorMetaDataList": [],
                            "keyList": ["START", "END"]
                        }, {
                            "queryOperator": "EQUAL",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "NOT_EQUAL",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "LESS",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "GREATER",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "LESS_OR_EQUAL",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "GREATER_OR_EQUAL",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }],
                        "keyList": ["FIX_TIME"]
                    }]
                }, {
                    "queryCode": "last_mileage",
                    "queryDescribe": null,
                    "tableCode": "CUSTOMER_LABEL_DAILY",
                    "name": "上次进站里程",
                    "unit": "公里",
                    "dataType": "NUMBER",
                    "timeDirection": null,
                    "timePattern": null,
                    "operatorMetaDataList": [{
                        "queryOperator": "BETWEEN",
                        "defaultFlag": 1,
                        "subOperatorMetaDataList": [],
                        "keyList": ["START", "END"]
                    }, {
                        "queryOperator": "EQUAL",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "NOT_EQUAL",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "LESS",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "GREATER",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "LESS_OR_EQUAL",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "GREATER_OR_EQUAL",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }]
                }, {
                    "queryCode": "last_mileage_maint",
                    "queryDescribe": null,
                    "tableCode": "CUSTOMER_LABEL_DAILY",
                    "name": "上次保养里程",
                    "unit": "公里",
                    "dataType": "NUMBER",
                    "timeDirection": null,
                    "timePattern": null,
                    "operatorMetaDataList": [{
                        "queryOperator": "BETWEEN",
                        "defaultFlag": 1,
                        "subOperatorMetaDataList": [],
                        "keyList": ["START", "END"]
                    }, {
                        "queryOperator": "EQUAL",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "NOT_EQUAL",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "LESS",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "GREATER",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "LESS_OR_EQUAL",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "GREATER_OR_EQUAL",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }]
                }, {
                    "queryCode": "last_mileage_daily_avg",
                    "queryDescribe": null,
                    "tableCode": "CUSTOMER_LABEL_DAILY",
                    "name": "上次进站日均里程",
                    "unit": null,
                    "dataType": "NUMBER",
                    "timeDirection": null,
                    "timePattern": null,
                    "operatorMetaDataList": [{
                        "queryOperator": "BETWEEN",
                        "defaultFlag": 1,
                        "subOperatorMetaDataList": [],
                        "keyList": ["START", "END"]
                    }, {
                        "queryOperator": "EQUAL",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "NOT_EQUAL",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "LESS",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "GREATER",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "LESS_OR_EQUAL",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "GREATER_OR_EQUAL",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }]
                }, {
                    "queryCode": "last_sa",
                    "queryDescribe": null,
                    "tableCode": "CUSTOMER_LABEL_DAILY",
                    "name": "上次接待服务顾问",
                    "unit": null,
                    "dataType": "ENUM",
                    "timeDirection": null,
                    "timePattern": null,
                    "operatorMetaDataList": [{
                        "queryOperator": "SINGLE_IN",
                        "defaultFlag": 1,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "SINGLE_NOT_IN",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }]
                }, {
                    "queryCode": "last_repair_partcat_lists",
                    "queryDescribe": null,
                    "tableCode": "CUSTOMER_LABEL_DAILY",
                    "name": "上次维修配件类型列表",
                    "unit": null,
                    "dataType": "ENUM",
                    "timeDirection": null,
                    "timePattern": null,
                    "operatorMetaDataList": [{
                        "queryOperator": "FIND_IN",
                        "defaultFlag": 1,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "FIND_ONLY_HAVE",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "FIND_ALL_HAVE",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }]
                }]
            }, {
                "groupName": "配件标签",
                "metaDataList": [{
                    "queryCode": "part_freq_last3years",
                    "queryDescribe": null,
                    "tableCode": "PART_LABEL",
                    "name": "最近3年配件更换次数",
                    "unit": "次",
                    "dataType": "NUMBER",
                    "timeDirection": null,
                    "timePattern": null,
                    "operatorMetaDataList": [{
                        "queryOperator": "BETWEEN",
                        "defaultFlag": 1,
                        "subOperatorMetaDataList": [],
                        "keyList": ["START", "END"]
                    }, {
                        "queryOperator": "EQUAL",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "NOT_EQUAL",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "LESS",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "GREATER",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "LESS_OR_EQUAL",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "GREATER_OR_EQUAL",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }]
                }, {
                    "queryCode": "part_monetary_last3years",
                    "queryDescribe": null,
                    "tableCode": "PART_LABEL",
                    "name": "最近3年配件更换总金额",
                    "unit": "元",
                    "dataType": "NUMBER",
                    "timeDirection": null,
                    "timePattern": null,
                    "operatorMetaDataList": [{
                        "queryOperator": "BETWEEN",
                        "defaultFlag": 1,
                        "subOperatorMetaDataList": [],
                        "keyList": ["START", "END"]
                    }, {
                        "queryOperator": "EQUAL",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "NOT_EQUAL",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "LESS",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "GREATER",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "LESS_OR_EQUAL",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "GREATER_OR_EQUAL",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }]
                }, {
                    "queryCode": "part_last_time",
                    "queryDescribe": null,
                    "tableCode": "PART_LABEL",
                    "name": "配件最后更换时间",
                    "unit": "天",
                    "dataType": "DATE",
                    "timeDirection": "BEFORE",
                    "timePattern": "yyyy-MM-dd",
                    "operatorMetaDataList": [{
                        "queryOperator": "ABSOLUTE_TIME",
                        "defaultFlag": 1,
                        "subOperatorMetaDataList": [],
                        "keyList": ["START", "END"]
                    }, {
                        "queryOperator": "RELATIVE_CURRENT_TIME",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [{
                            "queryOperator": "BETWEEN",
                            "defaultFlag": 1,
                            "subOperatorMetaDataList": [],
                            "keyList": ["START", "END"]
                        }, {
                            "queryOperator": "EQUAL",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "NOT_EQUAL",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "LESS",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "GREATER",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "LESS_OR_EQUAL",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "GREATER_OR_EQUAL",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }],
                        "keyList": []
                    }, {
                        "queryOperator": "RELATIVE_FIX_TIME",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [{
                            "queryOperator": "BETWEEN",
                            "defaultFlag": 1,
                            "subOperatorMetaDataList": [],
                            "keyList": ["START", "END"]
                        }, {
                            "queryOperator": "EQUAL",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "NOT_EQUAL",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "LESS",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "GREATER",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "LESS_OR_EQUAL",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "GREATER_OR_EQUAL",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }],
                        "keyList": ["FIX_TIME"]
                    }]
                }, {
                    "queryCode": "part_duration_gap",
                    "queryDescribe": null,
                    "tableCode": "PART_LABEL",
                    "name": "配件最后更换时长间隔",
                    "unit": "天",
                    "dataType": "NUMBER",
                    "timeDirection": null,
                    "timePattern": null,
                    "operatorMetaDataList": [{
                        "queryOperator": "BETWEEN",
                        "defaultFlag": 1,
                        "subOperatorMetaDataList": [],
                        "keyList": ["START", "END"]
                    }, {
                        "queryOperator": "EQUAL",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "NOT_EQUAL",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "LESS",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "GREATER",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "LESS_OR_EQUAL",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "GREATER_OR_EQUAL",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }]
                }, {
                    "queryCode": "part_mileage_gap",
                    "queryDescribe": null,
                    "tableCode": "PART_LABEL",
                    "name": "配件最后更换里程间隔",
                    "unit": "公里",
                    "dataType": "NUMBER",
                    "timeDirection": null,
                    "timePattern": null,
                    "operatorMetaDataList": [{
                        "queryOperator": "BETWEEN",
                        "defaultFlag": 1,
                        "subOperatorMetaDataList": [],
                        "keyList": ["START", "END"]
                    }, {
                        "queryOperator": "EQUAL",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "NOT_EQUAL",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "LESS",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "GREATER",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "LESS_OR_EQUAL",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "GREATER_OR_EQUAL",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }]
                }, {
                    "queryCode": "part_recommend_decile",
                    "queryDescribe": "选择的Top百分数越小、配件更换可能性越高",
                    "tableCode": "PART_RECOMMEND_LABEL",
                    "name": "配件推荐概率TOP分位",
                    "unit": null,
                    "dataType": "PERCENT",
                    "timeDirection": null,
                    "timePattern": null,
                    "operatorMetaDataList": [{
                        "queryOperator": "SELECT",
                        "defaultFlag": 1,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }]
                }, {
                    "queryCode": "recommend_part_category",
                    "queryDescribe": "需要预测的要更换的目标配件",
                    "tableCode": "PART_RECOMMEND_LABEL",
                    "name": "推荐更换配件",
                    "unit": null,
                    "dataType": "ENUM",
                    "timeDirection": null,
                    "timePattern": null,
                    "operatorMetaDataList": [{
                        "queryOperator": "IN",
                        "defaultFlag": 1,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "ONLY_HAVE",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "ALL_HAVE",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }]
                }]
            }, {
                "groupName": "邀约活动",
                "metaDataList": [{
                    "queryCode": "last_call_time",
                    "queryDescribe": null,
                    "tableCode": "CUSTOMER_LABEL_DAILY",
                    "name": "上次呼叫时间",
                    "unit": "天",
                    "dataType": "DATE",
                    "timeDirection": "BEFORE",
                    "timePattern": "yyyy-MM-dd",
                    "operatorMetaDataList": [{
                        "queryOperator": "ABSOLUTE_TIME",
                        "defaultFlag": 1,
                        "subOperatorMetaDataList": [],
                        "keyList": ["START", "END"]
                    }, {
                        "queryOperator": "RELATIVE_CURRENT_TIME",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [{
                            "queryOperator": "BETWEEN",
                            "defaultFlag": 1,
                            "subOperatorMetaDataList": [],
                            "keyList": ["START", "END"]
                        }, {
                            "queryOperator": "EQUAL",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "NOT_EQUAL",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "LESS",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "GREATER",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "LESS_OR_EQUAL",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "GREATER_OR_EQUAL",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }],
                        "keyList": []
                    }, {
                        "queryOperator": "RELATIVE_FIX_TIME",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [{
                            "queryOperator": "BETWEEN",
                            "defaultFlag": 1,
                            "subOperatorMetaDataList": [],
                            "keyList": ["START", "END"]
                        }, {
                            "queryOperator": "EQUAL",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "NOT_EQUAL",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "LESS",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "GREATER",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "LESS_OR_EQUAL",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "GREATER_OR_EQUAL",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }],
                        "keyList": ["FIX_TIME"]
                    }]
                }]
            }, {
                "groupName": "工单属性",
                "metaDataList": [{
                    "queryCode": "main_settle_date",
                    "queryDescribe": null,
                    "tableCode": "REPAIR_ORDER",
                    "name": "结算日期",
                    "unit": "天",
                    "dataType": "DATE",
                    "timeDirection": "BEFORE",
                    "timePattern": "yyyy-MM-dd",
                    "operatorMetaDataList": [{
                        "queryOperator": "ABSOLUTE_TIME",
                        "defaultFlag": 1,
                        "subOperatorMetaDataList": [],
                        "keyList": ["START", "END"]
                    }, {
                        "queryOperator": "RELATIVE_CURRENT_TIME",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [{
                            "queryOperator": "BETWEEN",
                            "defaultFlag": 1,
                            "subOperatorMetaDataList": [],
                            "keyList": ["START", "END"]
                        }, {
                            "queryOperator": "EQUAL",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "NOT_EQUAL",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "LESS",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "GREATER",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "LESS_OR_EQUAL",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "GREATER_OR_EQUAL",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }],
                        "keyList": []
                    }, {
                        "queryOperator": "RELATIVE_FIX_TIME",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [{
                            "queryOperator": "BETWEEN",
                            "defaultFlag": 1,
                            "subOperatorMetaDataList": [],
                            "keyList": ["START", "END"]
                        }, {
                            "queryOperator": "EQUAL",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "NOT_EQUAL",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "LESS",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "GREATER",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "LESS_OR_EQUAL",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "GREATER_OR_EQUAL",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }],
                        "keyList": ["FIX_TIME"]
                    }]
                }, {
                    "queryCode": "main_repair_type_secondary_category",
                    "queryDescribe": null,
                    "tableCode": "REPAIR_ORDER",
                    "name": "维修类别",
                    "unit": null,
                    "dataType": "ENUM",
                    "timeDirection": null,
                    "timePattern": null,
                    "operatorMetaDataList": [{
                        "queryOperator": "IN",
                        "defaultFlag": 1,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "ONLY_HAVE",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "ALL_HAVE",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }]
                }, {
                    "queryCode": "main_total_fee",
                    "queryDescribe": null,
                    "tableCode": "REPAIR_ORDER",
                    "name": "总金额",
                    "unit": "元",
                    "dataType": "NUMBER",
                    "timeDirection": null,
                    "timePattern": null,
                    "operatorMetaDataList": [{
                        "queryOperator": "BETWEEN",
                        "defaultFlag": 1,
                        "subOperatorMetaDataList": [],
                        "keyList": ["START", "END"]
                    }, {
                        "queryOperator": "EQUAL",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "NOT_EQUAL",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "LESS",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "GREATER",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "LESS_OR_EQUAL",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "GREATER_OR_EQUAL",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }]
                }, {
                    "queryCode": "order_part_category",
                    "queryDescribe": null,
                    "tableCode": "REPAIR_ORDER_PART_DETAIL",
                    "name": "配件分类",
                    "unit": null,
                    "dataType": "ENUM",
                    "timeDirection": null,
                    "timePattern": null,
                    "operatorMetaDataList": [{
                        "queryOperator": "IN",
                        "defaultFlag": 1,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "ONLY_HAVE",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "ALL_HAVE",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }]
                }, {
                    "queryCode": "order_part_name",
                    "queryDescribe": null,
                    "tableCode": "REPAIR_ORDER_PART_DETAIL",
                    "name": "配件名称",
                    "unit": null,
                    "dataType": "ENUM",
                    "timeDirection": null,
                    "timePattern": null,
                    "operatorMetaDataList": [{
                        "queryOperator": "IN",
                        "defaultFlag": 1,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "ONLY_HAVE",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "ALL_HAVE",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }]
                }]
            }, {
                "groupName": "优惠项目",
                "metaDataList": [{
                    "queryCode": "repair_name",
                    "queryDescribe": null,
                    "tableCode": "REPAIR_RECOMMEND",
                    "name": "优惠推荐项目",
                    "unit": null,
                    "dataType": "ENUM",
                    "timeDirection": null,
                    "timePattern": null,
                    "operatorMetaDataList": [{
                        "queryOperator": "IN",
                        "defaultFlag": 1,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "ONLY_HAVE",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "ALL_HAVE",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }]
                }, {
                    "queryCode": "create_time",
                    "queryDescribe": null,
                    "tableCode": "REPAIR_RECOMMEND",
                    "name": "优惠推荐时间",
                    "unit": "天",
                    "dataType": "DATE",
                    "timeDirection": "BEFORE",
                    "timePattern": "yyyy-MM-dd",
                    "operatorMetaDataList": [{
                        "queryOperator": "ABSOLUTE_TIME",
                        "defaultFlag": 1,
                        "subOperatorMetaDataList": [],
                        "keyList": ["START", "END"]
                    }, {
                        "queryOperator": "RELATIVE_CURRENT_TIME",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [{
                            "queryOperator": "BETWEEN",
                            "defaultFlag": 1,
                            "subOperatorMetaDataList": [],
                            "keyList": ["START", "END"]
                        }, {
                            "queryOperator": "EQUAL",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "NOT_EQUAL",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "LESS",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "GREATER",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "LESS_OR_EQUAL",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "GREATER_OR_EQUAL",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }],
                        "keyList": []
                    }, {
                        "queryOperator": "RELATIVE_FIX_TIME",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [{
                            "queryOperator": "BETWEEN",
                            "defaultFlag": 1,
                            "subOperatorMetaDataList": [],
                            "keyList": ["START", "END"]
                        }, {
                            "queryOperator": "EQUAL",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "NOT_EQUAL",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "LESS",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "GREATER",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "LESS_OR_EQUAL",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "GREATER_OR_EQUAL",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }],
                        "keyList": ["FIX_TIME"]
                    }]
                }]
            }, {
                "groupName": "呼叫任务",
                "metaDataList": [{
                    "queryCode": "call_invite_name",
                    "queryDescribe": null,
                    "tableCode": "INVITE_CALL_TASK_RESULT",
                    "name": "呼叫邀约名称",
                    "unit": null,
                    "dataType": "ENUM",
                    "timeDirection": null,
                    "timePattern": null,
                    "operatorMetaDataList": [{
                        "queryOperator": "IN",
                        "defaultFlag": 1,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "ONLY_HAVE",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "ALL_HAVE",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }]
                }, {
                    "queryCode": "invite_type_name",
                    "queryDescribe": null,
                    "tableCode": "INVITE_CALL_TASK_RESULT",
                    "name": "呼叫邀约类型",
                    "unit": null,
                    "dataType": "ENUM",
                    "timeDirection": null,
                    "timePattern": null,
                    "operatorMetaDataList": [{
                        "queryOperator": "IN",
                        "defaultFlag": 1,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "ONLY_HAVE",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "ALL_HAVE",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }]
                }, {
                    "queryCode": "call_result",
                    "queryDescribe": null,
                    "tableCode": "INVITE_CALL_TASK_RESULT",
                    "name": "呼叫结果",
                    "unit": null,
                    "dataType": "ENUM",
                    "timeDirection": null,
                    "timePattern": null,
                    "operatorMetaDataList": [{
                        "queryOperator": "IN",
                        "defaultFlag": 1,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "ONLY_HAVE",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "ALL_HAVE",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }]
                }, {
                    "queryCode": "task_result",
                    "queryDescribe": null,
                    "tableCode": "INVITE_CALL_TASK_RESULT",
                    "name": "呼叫任务结果",
                    "unit": null,
                    "dataType": "ENUM",
                    "timeDirection": null,
                    "timePattern": null,
                    "operatorMetaDataList": [{
                        "queryOperator": "IN",
                        "defaultFlag": 1,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "ONLY_HAVE",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "ALL_HAVE",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }]
                }, {
                    "queryCode": "result_create_user",
                    "queryDescribe": null,
                    "tableCode": "INVITE_CALL_TASK_RESULT",
                    "name": "呼叫任务执行人",
                    "unit": null,
                    "dataType": "ENUM",
                    "timeDirection": null,
                    "timePattern": null,
                    "operatorMetaDataList": [{
                        "queryOperator": "IN",
                        "defaultFlag": 1,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "ONLY_HAVE",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "ALL_HAVE",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }]
                }, {
                    "queryCode": "next_invite_time",
                    "queryDescribe": null,
                    "tableCode": "INVITE_CALL_TASK_RESULT",
                    "name": "预约进站时间",
                    "unit": "天",
                    "dataType": "DATE",
                    "timeDirection": "AFTER",
                    "timePattern": "yyyy-MM-dd",
                    "operatorMetaDataList": [{
                        "queryOperator": "ABSOLUTE_TIME",
                        "defaultFlag": 1,
                        "subOperatorMetaDataList": [],
                        "keyList": ["START", "END"]
                    }, {
                        "queryOperator": "RELATIVE_CURRENT_TIME",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [{
                            "queryOperator": "BETWEEN",
                            "defaultFlag": 1,
                            "subOperatorMetaDataList": [],
                            "keyList": ["START", "END"]
                        }, {
                            "queryOperator": "EQUAL",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "NOT_EQUAL",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "LESS",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "GREATER",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "LESS_OR_EQUAL",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "GREATER_OR_EQUAL",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }],
                        "keyList": []
                    }, {
                        "queryOperator": "RELATIVE_FIX_TIME",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [{
                            "queryOperator": "BETWEEN",
                            "defaultFlag": 1,
                            "subOperatorMetaDataList": [],
                            "keyList": ["START", "END"]
                        }, {
                            "queryOperator": "EQUAL",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "NOT_EQUAL",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "LESS",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "GREATER",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "LESS_OR_EQUAL",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "GREATER_OR_EQUAL",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }],
                        "keyList": ["FIX_TIME"]
                    }]
                }]
            }, {
                "groupName": "保险数据",
                "metaDataList": [{
                    "queryCode": "expire_date",
                    "queryDescribe": null,
                    "tableCode": "INSURANCE_DATA",
                    "name": "保险到期时间",
                    "unit": "天",
                    "dataType": "DATE",
                    "timeDirection": "AFTER",
                    "timePattern": "yyyy-MM-dd",
                    "operatorMetaDataList": [{
                        "queryOperator": "ABSOLUTE_TIME",
                        "defaultFlag": 1,
                        "subOperatorMetaDataList": [],
                        "keyList": ["START", "END"]
                    }, {
                        "queryOperator": "RELATIVE_CURRENT_TIME",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [{
                            "queryOperator": "BETWEEN",
                            "defaultFlag": 1,
                            "subOperatorMetaDataList": [],
                            "keyList": ["START", "END"]
                        }, {
                            "queryOperator": "EQUAL",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "NOT_EQUAL",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "LESS",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "GREATER",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "LESS_OR_EQUAL",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "GREATER_OR_EQUAL",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }],
                        "keyList": []
                    }, {
                        "queryOperator": "RELATIVE_FIX_TIME",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [{
                            "queryOperator": "BETWEEN",
                            "defaultFlag": 1,
                            "subOperatorMetaDataList": [],
                            "keyList": ["START", "END"]
                        }, {
                            "queryOperator": "EQUAL",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "NOT_EQUAL",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "LESS",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "GREATER",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "LESS_OR_EQUAL",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "GREATER_OR_EQUAL",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }],
                        "keyList": ["FIX_TIME"]
                    }]
                }, {
                    "queryCode": "insurance_category",
                    "queryDescribe": null,
                    "tableCode": "INSURANCE_DATA",
                    "name": "保险险种",
                    "unit": null,
                    "dataType": "ENUM",
                    "timeDirection": null,
                    "timePattern": null,
                    "operatorMetaDataList": [{
                        "queryOperator": "IN",
                        "defaultFlag": 1,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "ONLY_HAVE",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "ALL_HAVE",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }]
                }, {
                    "queryCode": "signing_date",
                    "queryDescribe": null,
                    "tableCode": "INSURANCE_DATA",
                    "name": "签单日期",
                    "unit": "天",
                    "dataType": "DATE",
                    "timeDirection": "BEFORE",
                    "timePattern": "yyyy-MM-dd",
                    "operatorMetaDataList": [{
                        "queryOperator": "ABSOLUTE_TIME",
                        "defaultFlag": 1,
                        "subOperatorMetaDataList": [],
                        "keyList": ["START", "END"]
                    }, {
                        "queryOperator": "RELATIVE_CURRENT_TIME",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [{
                            "queryOperator": "BETWEEN",
                            "defaultFlag": 1,
                            "subOperatorMetaDataList": [],
                            "keyList": ["START", "END"]
                        }, {
                            "queryOperator": "EQUAL",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "NOT_EQUAL",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "LESS",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "GREATER",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "LESS_OR_EQUAL",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "GREATER_OR_EQUAL",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }],
                        "keyList": []
                    }, {
                        "queryOperator": "RELATIVE_FIX_TIME",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [{
                            "queryOperator": "BETWEEN",
                            "defaultFlag": 1,
                            "subOperatorMetaDataList": [],
                            "keyList": ["START", "END"]
                        }, {
                            "queryOperator": "EQUAL",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "NOT_EQUAL",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "LESS",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "GREATER",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "LESS_OR_EQUAL",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "GREATER_OR_EQUAL",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }],
                        "keyList": ["FIX_TIME"]
                    }]
                }]
            }, {
                "groupName": "优惠订购",
                "metaDataList": [{
                    "queryCode": "repair_name",
                    "queryDescribe": null,
                    "tableCode": "DISCOUNTS_DATA",
                    "name": "优惠套餐名称",
                    "unit": null,
                    "dataType": "ENUM",
                    "timeDirection": null,
                    "timePattern": null,
                    "operatorMetaDataList": [{
                        "queryOperator": "IN",
                        "defaultFlag": 1,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "ONLY_HAVE",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "ALL_HAVE",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }]
                }, {
                    "queryCode": "order_date",
                    "queryDescribe": null,
                    "tableCode": "DISCOUNTS_ORDER",
                    "name": "订购日期",
                    "unit": "天",
                    "dataType": "DATE",
                    "timeDirection": "BEFORE",
                    "timePattern": "yyyy-MM-dd",
                    "operatorMetaDataList": [{
                        "queryOperator": "ABSOLUTE_TIME",
                        "defaultFlag": 1,
                        "subOperatorMetaDataList": [],
                        "keyList": ["START", "END"]
                    }, {
                        "queryOperator": "RELATIVE_CURRENT_TIME",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [{
                            "queryOperator": "BETWEEN",
                            "defaultFlag": 1,
                            "subOperatorMetaDataList": [],
                            "keyList": ["START", "END"]
                        }, {
                            "queryOperator": "EQUAL",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "NOT_EQUAL",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "LESS",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "GREATER",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "LESS_OR_EQUAL",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "GREATER_OR_EQUAL",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }],
                        "keyList": []
                    }, {
                        "queryOperator": "RELATIVE_FIX_TIME",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [{
                            "queryOperator": "BETWEEN",
                            "defaultFlag": 1,
                            "subOperatorMetaDataList": [],
                            "keyList": ["START", "END"]
                        }, {
                            "queryOperator": "EQUAL",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "NOT_EQUAL",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "LESS",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "GREATER",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "LESS_OR_EQUAL",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "GREATER_OR_EQUAL",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }],
                        "keyList": ["FIX_TIME"]
                    }]
                }, {
                    "queryCode": "remain_count",
                    "queryDescribe": null,
                    "tableCode": "DISCOUNTS_ORDER",
                    "name": "剩余次数",
                    "unit": "次",
                    "dataType": "NUMBER",
                    "timeDirection": null,
                    "timePattern": null,
                    "operatorMetaDataList": [{
                        "queryOperator": "BETWEEN",
                        "defaultFlag": 1,
                        "subOperatorMetaDataList": [],
                        "keyList": ["START", "END"]
                    }, {
                        "queryOperator": "EQUAL",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "NOT_EQUAL",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "LESS",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "GREATER",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "LESS_OR_EQUAL",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }, {
                        "queryOperator": "GREATER_OR_EQUAL",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [],
                        "keyList": []
                    }]
                }, {
                    "queryCode": "expire_date",
                    "queryDescribe": null,
                    "tableCode": "DISCOUNTS_ORDER",
                    "name": "失效日期",
                    "unit": "天",
                    "dataType": "DATE",
                    "timeDirection": "AFTER",
                    "timePattern": "yyyy-MM-dd",
                    "operatorMetaDataList": [{
                        "queryOperator": "ABSOLUTE_TIME",
                        "defaultFlag": 1,
                        "subOperatorMetaDataList": [],
                        "keyList": ["START", "END"]
                    }, {
                        "queryOperator": "RELATIVE_CURRENT_TIME",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [{
                            "queryOperator": "BETWEEN",
                            "defaultFlag": 1,
                            "subOperatorMetaDataList": [],
                            "keyList": ["START", "END"]
                        }, {
                            "queryOperator": "EQUAL",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "NOT_EQUAL",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "LESS",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "GREATER",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "LESS_OR_EQUAL",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "GREATER_OR_EQUAL",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }],
                        "keyList": []
                    }, {
                        "queryOperator": "RELATIVE_FIX_TIME",
                        "defaultFlag": 2,
                        "subOperatorMetaDataList": [{
                            "queryOperator": "BETWEEN",
                            "defaultFlag": 1,
                            "subOperatorMetaDataList": [],
                            "keyList": ["START", "END"]
                        }, {
                            "queryOperator": "EQUAL",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "NOT_EQUAL",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "LESS",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "GREATER",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "LESS_OR_EQUAL",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }, {
                            "queryOperator": "GREATER_OR_EQUAL",
                            "defaultFlag": 2,
                            "subOperatorMetaDataList": [],
                            "keyList": []
                        }],
                        "keyList": ["FIX_TIME"]
                    }]
                }]
            }]
    }
// const response = await customersGroupMetaDataRequest();
    // if (response.code === '00000') {
    // 	this.customersGroupMetaDataList = response.data;
    // }
}
export default TaskManagerStore

