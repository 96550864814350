import React, { useState, useEffect } from 'react'
import D from 'src/components/AntD'
import { Form, Button, DatePicker, Space, Radio, RadioChangeEvent, Tooltip } from 'antd'
import _ from 'underscore'
import moment from 'moment'
import { InfoCircleOutlined } from '@ant-design/icons'
import { getTooltipContent } from 'src/api/screenCondition'


import { CustomerMetadata } from 'src/views/taskManagement/customerMetadata'

interface Iprops {
    customerMetadata: CustomerMetadata
    antForm: any
    index: number
    fieldIndex: number
    refresh: any
    isDetail: boolean
    i: number
}

const DateComponent = ({ customerMetadata, antForm, index,i, fieldIndex, refresh, isDetail }: Iprops) => {
    const { RangePicker } = DatePicker
    const [tooltip, setTooltip] = useState('')

    const queryTooltip = async ({ uniqueId, operator, subOperator, values }: any) => {
        const data = await getTooltipContent({
            uniqueId,
            operator,
            subOperator,
            values
        })
        setTooltip(data.data || '')
    }

    useEffect(() => {
        getTooltip()
    }, [customerMetadata])

    const getTooltip = () => {
        const attributeMetadata = customerMetadata.findAttributeMetadataByIndex(index, i)
        if (['RT_C_T', 'RT_C_D'].includes(attributeMetadata.operatorValue)) {
            if (attributeMetadata.extra.value) {
                queryTooltip({
                    uniqueId: attributeMetadata.uniqueId,
                    operator: attributeMetadata.operatorValue,
                    subOperator: attributeMetadata.subOperatorValue,
                    values: [attributeMetadata.extra.value]
                })
            } else if (attributeMetadata.extra.start && attributeMetadata.extra.end) {
                queryTooltip({
                    uniqueId: attributeMetadata.uniqueId,
                    operator: attributeMetadata.operatorValue,
                    subOperator: attributeMetadata.subOperatorValue,
                    values: [attributeMetadata.extra.start, attributeMetadata.extra.end]
                })
            }
        }
    }
    
    const getExtraComponent = () => {
        const attributeMetadata = customerMetadata.findAttributeMetadataByIndex(index, i)
        const operatorInfo = _.findWhere(attributeMetadata.operators || [], { operator: attributeMetadata.operatorValue })
        const subOperatorInfo = _.findWhere(operatorInfo?.subOperators || [], { subOperator: attributeMetadata.subOperatorValue })
        if ( subOperatorInfo?.subOperator === 'BT' ) {
            // 区分绝对时间和当前天数RT_C_D AT
            if (attributeMetadata?.operatorValue === "RT_C_D") {
                return (
                    <div className='flex item-center'>
                        <D.Input type="number" value={attributeMetadata.extra && attributeMetadata.extra.start} onChange={({ target: { value } }) => {
                            const n = value.replace(/[^0-9]/, '')
                            const extra = {
                                end: attributeMetadata.extra && attributeMetadata.extra.end || '',
                                start: n ? Number(n) : ''
                            }
                            const formData = customerMetadata.updateExtraValue(extra, index, i).getCustomerAttributeData()
                            antForm.getFieldValue('metadataForm')[index].metadataInnerForm[i] = formData[index][i]
                            antForm.setFieldsValue({
                                metadataForm: antForm.getFieldValue('metadataForm')
                            })
                            getTooltip()
                        }} placeholder="请输入"  disabled={isDetail} style={{ width: '83px' }} />
                        <span style={{ margin: '0 10px' }}>至</span>
                        <D.Input type="number" value={attributeMetadata.extra && attributeMetadata.extra.end} onChange={({ target: { value } }) => {
                            const n = value.replace(/[^0-9]/, '')
                            const extra = {
                                start: attributeMetadata.extra && attributeMetadata.extra.start || '',
                                end: n ? Number(n) : ''
                            }
                            const formData = customerMetadata.updateExtraValue(extra, index, i).getCustomerAttributeData()
                            antForm.getFieldValue('metadataForm')[index].metadataInnerForm[i] = formData[index][i]
                            antForm.setFieldsValue({
                                metadataForm: antForm.getFieldValue('metadataForm')
                            })
                            getTooltip()
                        }} placeholder="请输入"  disabled={isDetail} style={{ width: '83px' }} />
                        {
                            attributeMetadata.displayUnit && <div className='metadata-unit'>{attributeMetadata.displayUnit}</div>
                        }
                        {
                            ['RT_C_T', 'RT_C_D'].includes(attributeMetadata.operatorValue) && attributeMetadata.extra.start && (
                                <Tooltip title={tooltip}>
                                    <InfoCircleOutlined style={{cursor: 'pointer', color: '#2878ff', marginLeft: '10px'}} />
                                </Tooltip>
                            )
                        }
                    </div>
                )
            } else {
                return (
                    <div className='flex item-center'>
                        <RangePicker value={attributeMetadata.extra && attributeMetadata.extra.start && [moment(attributeMetadata.extra.start), moment(attributeMetadata.extra.end)]} onChange={(value) => {
                            const extra = {
                                start: value && value[0]?.format('YYYY-MM-DD'),
                                end: value && value[1]?.format('YYYY-MM-DD')
                            }
                            const formData = customerMetadata.updateExtraValue(extra, index, i).getCustomerAttributeData()
                            antForm.getFieldValue('metadataForm')[index].metadataInnerForm[i] = formData[index][i]
                            antForm.setFieldsValue({
                                metadataForm: antForm.getFieldValue('metadataForm')
                            })
                            if (value) {
                                queryTooltip({
                                    uniqueId: attributeMetadata.uniqueId,
                                    operator: attributeMetadata.operatorValue,
                                    subOperator: attributeMetadata.subOperatorValue,
                                    values: [value[0]?.format('YYYY-MM-DD HH:mm:ss'), value && value[1]?.format('YYYY-MM-DD HH:mm:ss')]
                                })
                            }
                        }} style={{ width: 260 }} disabled={isDetail} placeholder={['请选择日期', '请选择日期']}/>
                        {
                            ['RT_C_T', 'RT_C_D'].includes(attributeMetadata.operatorValue) && attributeMetadata.extra.start && (
                                <Tooltip title={tooltip}>
                                    <InfoCircleOutlined style={{cursor: 'pointer', color: '#2878ff', marginLeft: '10px'}} />
                                </Tooltip>
                            )
                        }
                    </div>
                )
            }
        }
        else if(attributeMetadata.operatorValue === 'AT') {
            return <DatePicker value={attributeMetadata.extra && attributeMetadata.extra.value && moment(attributeMetadata.extra.value)} onChange={(value) => {
                const extra = {
                    value: value?.format('YYYY-MM-DD')
                }
                const formData = customerMetadata.updateExtraValue(extra, index, i).getCustomerAttributeData()
                antForm.getFieldValue('metadataForm')[index].metadataInnerForm[i] = formData[index][i]
                antForm.setFieldsValue({
                    metadataForm: antForm.getFieldValue('metadataForm')
                })
            }} style={{ width: 200 }} disabled={isDetail}/>
        }else if (subOperatorInfo?.valueType === 'SINGLE') {
            if (operatorInfo?.operator === 'RT_C_T') {
                return (
                    <div className='flex item-center'>
                        <D.Input value={attributeMetadata.extra && attributeMetadata.extra.value} onChange={({ target: { value } }) => {
                            const extra = {
                                ...attributeMetadata.extra,
                                value
                            }
                            const formData = customerMetadata.updateExtraValue(extra, index, i).getCustomerAttributeData()
                            antForm.getFieldValue('metadataForm')[index].metadataInnerForm[i] = formData[index][i]
                            antForm.setFieldsValue({
                                metadataForm: antForm.getFieldValue('metadataForm')
                            })
                            queryTooltip({
                                uniqueId: attributeMetadata.uniqueId,
                                operator: attributeMetadata.operatorValue,
                                subOperator: attributeMetadata.extra?.extraSub ?  attributeMetadata.extra.extraSub : attributeMetadata.subOperatorValue,
                                values: [value]
                            })
                        }} placeholder="请输入"  disabled={isDetail} style={{ width: 100 }} />
                        <span style={{marginLeft: '12px'}}>天</span>
                        <D.Select value={attributeMetadata.extra && attributeMetadata.extra.extraSub} options={customerMetadata.getSubOptionLinkage(index, i)} onChange={(values) => {
                            const extra = {
                                ...attributeMetadata.extra,
                                extraSub: values
                            }
                            const formData = customerMetadata.updateExtraValue(extra, index, i).getCustomerAttributeData()
                            antForm.getFieldValue('metadataForm')[index].metadataInnerForm[i] = formData[index][i]
                            antForm.setFieldsValue({
                                metadataForm: antForm.getFieldValue('metadataForm')
                            })
                            queryTooltip({
                                uniqueId: attributeMetadata.uniqueId,
                                operator: attributeMetadata.operatorValue,
                                subOperator: values,
                                values: [attributeMetadata.extra.value]
                            })

                        }} allowClear={false} placeholder="请选择"  disabled={isDetail} style={{ width: 100 ,marginLeft:'8px'}} />
                        {
                            ['RT_C_T', 'RT_C_D'].includes(attributeMetadata.operatorValue) && attributeMetadata.extra.value && (
                                <Tooltip title={tooltip}>
                                    <InfoCircleOutlined style={{cursor: 'pointer', color: '#2878ff', marginLeft: '10px'}} />
                                </Tooltip>
                            )
                        }
                    </div>
                )
            }
            return (
                <div className='flex item-center'>
                    <D.Input value={attributeMetadata.extra && attributeMetadata.extra.value} onChange={({ target: { value } }) => {
                        const extra = {
                            value
                        }
                        const formData = customerMetadata.updateExtraValue(extra, index, i).getCustomerAttributeData()
                        antForm.getFieldValue('metadataForm')[index].metadataInnerForm[i] = formData[index][i]
                        antForm.setFieldsValue({
                            metadataForm: antForm.getFieldValue('metadataForm')
                        })
                        queryTooltip({
                            uniqueId: attributeMetadata.uniqueId,
                            operator: attributeMetadata.operatorValue,
                            subOperator: attributeMetadata.subOperatorValue,
                            values: [value]
                        })
                    }} placeholder="请输入"  disabled={isDetail} style={{ width: 200 }} />
                    {
                        ['RT_C_T', 'RT_C_D'].includes(attributeMetadata.operatorValue) && attributeMetadata.extra.value && (
                            <Tooltip title={tooltip}>
                                <InfoCircleOutlined style={{cursor: 'pointer', color: '#2878ff', marginLeft: '10px'}} />
                            </Tooltip>
                        )
                    }
                </div>
            )
        }
        return <div />
    }

    const getSubOperatorComponent = () => {
        const attributeMetadata = customerMetadata.findAttributeMetadataByIndex(index, i)
        const operatorInfo = _.findWhere(attributeMetadata.operators || [], { operator: attributeMetadata.operatorValue })
        if (operatorInfo?.subOperators) {
            return (
                <D.Select options={customerMetadata.getSubOperatorOption(index, i)} onChange={(value: any) => {
                    if (['P_I_D', 'P_B_D', 'F_I_D', 'F_A_D'].includes(value)) {
                        const formData = customerMetadata.updateExtraSubValueRT(value as string, index, i).getCustomerAttributeData()
                        antForm.getFieldValue('metadataForm')[index].metadataInnerForm[i] = formData[index][i]
                        antForm.setFieldsValue({
                            metadataForm: antForm.getFieldValue('metadataForm')
                        })
                    } else {
                        const formData = customerMetadata.updateSubOperatorValueByIndex(value as string, index, i).getCustomerAttributeData()
                        antForm.getFieldValue('metadataForm')[index].metadataInnerForm[i] = formData[index][i]
                        antForm.setFieldsValue({
                            metadataForm: antForm.getFieldValue('metadataForm')
                        })
                    }
                   
    
                }} allowClear={false} placeholder="请选择"  disabled={isDetail} style={{ width:operatorInfo.operator !== 'RT_C_T' ? 200 : 100 }} />
            )
        }
        return <div />
    }
    
    const formList = [
        {
            label: '',
            name: 'queryName',
            children: <D.Select options={customerMetadata.getGroupOptions()} onChange={(value) => {
                const formData = customerMetadata.updateQueryNameByIndex(value as string, index, i).getCustomerAttributeData()
                antForm.getFieldValue('metadataForm')[index].metadataInnerForm[i] = formData[index][i]
                antForm.setFieldsValue({
                    metadataForm: antForm.getFieldValue('metadataForm')
                })
                refresh()
            }} allowClear={false} placeholder="请选择"  disabled={isDetail} style={{ width: 200 }} />,
        },
        {
            label: '',
            name: 'operatorValue',
            children: <D.Select options={customerMetadata.getOperatorOption(index, i)} onChange={(value) => {
                const formData = customerMetadata.updateOperatorValueByIndex(value as string, index, i).getCustomerAttributeData()
                antForm.getFieldValue('metadataForm')[index].metadataInnerForm[i] = formData[index][i]
                antForm.setFieldsValue({
                    metadataForm: antForm.getFieldValue('metadataForm')
                })

            }} allowClear={false} placeholder="请选择"  disabled={isDetail} style={{ width: 200 }} />,
        },
        {
            label: '',
            name: 'subOperatorValue',
            children: getSubOperatorComponent()
        },
        {
            label: '',
            name: 'extraValue',
            children: getExtraComponent(),
        }
    ]

    return (
        formList.map((f, i) => (
            <Form.Item key={f.name} {...f} name={[fieldIndex, f.name]} />
        ))
    )
}
DateComponent.indentify = 'DATE'

export default DateComponent