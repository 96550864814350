import Select from './Select'
import Table from './Table'
import Input from './Input'
import Tabs from './Tabs'
import RangePicker from './rangePicker'
import DatePicker from './DatePicker'

const D = {
    Select,
    Table,
    Input,
    Tabs,
    RangePicker,
    DatePicker,
}
export default D
