import request from './request'
import {
    MetadataDetailParams,
    CustomersHistoryOrderParams
} from 'src/types/customers'

// 获取查询元数据
export const getCustomerScreenMetadataRequest= async (data = 'simple') => {
    return request({
        url: `/core-data-service/metadata/customer/list/${data}`,
        method: 'get',
    });
};

export const customersColumnsMetadataRequest = () => {
    return request({
        url: '/customer-center/query/meta/data/search',
        method: 'post',
    });
}
export const customersMedataEnumRequest = (data: MetadataDetailParams) => {
    return request({
        url: '/customer-center/query/meta/data/enum',  
        method: 'get',
        params: data
    })
};

export const customersDetailsHistoryOrderRequest = (data: CustomersHistoryOrderParams) => {
    return request({
        url: '/customer-center/customer/repair/order/info/dynamic',
        method: 'post',
        params: data
    })
}

export const customersGroupMetaDataRequest = (data = {}) => {
    return request({
        url: '/customer-center/query/meta/data/group',
        data,
        method: 'get'
    })
}
// 统计人数接口
export const getStatisticsCustomer = (data: any) => {
    return request({
        url: '/core-data-service/query/customer/count',
        data,
        method: 'post'
    })
}

// 保存分组接口
export const saveCustomerGroup = (data: any) => {
    return request({
        url: '/core-data-service/group/save',
        data,
        method: 'post'
    })
}

// 预览列表接口
export const preivewCustomerGroup = (data: any) => {
    return request({
        url: '/core-data-service/query/customer/preview',
        data,
        method: 'post'
    })
}

// 获取车主详情数据

export const getCustomDetailData = (data = {}) => {
    return request({
        url: '/core-data-service/query/customer/info',
        params: data,
        method: 'post'
    })
}
// 获取车主详情订单数据 table
export const getCustomDetailTable = (data = {}) => {
    return request({
        url: '/core-data-service/query/customer/order/list',
        params: data,
        method: 'post'
    })
}

// 客户分组列表
export const getCustomList = (data: any) => {
    return request({
        url: '/core-data-service/group/list',
        data,
        method: 'post'
    })
}

// 获取客户分组 分组内的 搜索元数据
export const getCustomerGroupInfoMetadata = () => {
    return request({
        url: '/core-data-service/metadata/customer/list/group',
        method: 'get'
    })
}

export const getCustomerGroupInfoTableData = (data: any) => {
    return request({
        url: '/core-data-service/group/customer/list',
        method: 'post',
        data
    })
}

// 删除分组
export const deleteCustomerList = (data: any) => {
    return request({
        url: '/core-data-service/group/remove',
        data,
        method: 'post'
    })
}

// 客户分组列表筛选条件  创建人字典
export const getCreateUser = () => {
    return request({
        url: '/core-data-service/group/dict/create-user/list',
        method: 'get'
    })
}

// 获取下载字典
export const getDownloadDisc = () => {
    return request({
        url: '/core-data-service/metadata/download/customer',
        method: 'get',
    })
}

// 客户信息下载功能
export const customerDownload = (data: any) => {
    return request({
        url: '/core-data-service/query/customer/simple/download',
        method: 'post',
        data,
        responseType: 'blob'
    })
}

// 客户分组列表下载功能
export const customerGroupDownload = (data: any) => {
    return request({
        url: '/core-data-service/group/customer/list/download',
        method: 'post',
        data,
        responseType: 'blob'
    })
}

// 查看特征
export const getCharacterDataApi = async (data: any) => {
    return request({
        url: '/core-data-service/group/customer/feature/data',
        params: data,
        method: 'get',
    })
}

// 信息管理详情标签
export const getDetailTagsApi = (data: any) => {
    return request({
        url: '/core-data-service/customer/label/data/car_vin',
        params: data,
        method: 'get',
    })
}

// 信息管理详情标签字典
export const getDetailTagsDictApi = () => {
    return request({
        url: '/core-data-service/customer/label/data/dict',
        method: 'get',
    })
}
// 信息管理详情绑定客户标签
export const bindDetailTags = (data: any) => {
    return request({
        url: '/core-data-service/customer/label/action/bind',
        method: 'post',
        data
    })
}

// 信息管理详情解绑绑定客户标签
export const unbindDetailTags = (data: any) => {
    return request({
        url: '/core-data-service/customer/label/action/unbind',
        method: 'post',
        data
    })
}
// 系统标签
export const getSystemTagApi = (data: any) => {
    return request({
        url: '/core-data-service/customer/label/system/data/car_vin',
        method: 'get',
        params: data,
    })
}
