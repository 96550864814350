import React, { forwardRef } from 'react'
import { Moment } from 'moment'

import { DatePicker, DatePickerProps } from 'antd'

interface Iprops {
    change: (value: any | null) => void
    currentDate: any
}

const DatePickerComponent: React.FC<Iprops & DatePickerProps> = ({
    change,
    currentDate,
    ...rest
}) => {
    const [value, setValue] = React.useState<Moment | null>(null)
    React.useEffect(() => {
        setValue(currentDate)
    }, [currentDate])
    return (
        <DatePicker
            value={value}
            onChange={(newValue) => {
                setValue(newValue)
                change(newValue)
            }}
            {...rest}
        />
    )
}

export default DatePickerComponent
