import React from 'react'
import styled from 'styled-components'
// import CloseIcon from '@material-ui/icons/Close';
import { Modal, ModalProps, Button } from 'antd'

export type PageDialogProps = {
    open: boolean
    title: string | React.ReactNode
    children: React.ReactNode
    action?: React.ReactNode
    onClose: () => void
} & ModalProps

const DialogTitle = styled.div`
    padding: 0 6px;
    height: 48px;
    line-height: 48px;
    text-align: center;
    background-color: #f5f8fa;
    position: relative;

    & strong {
        font-size: 16px;
        color: #516f90;
        display: block;
        white-space: nowrap;
        padding: 0 50px;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    & button.close {
        position: absolute;
        z-index: 1;
        right: 7px;
        top: 7px;
    }
`
const DialogFooter = styled.div`
    background-color: #f5f8fa;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 46px;
    & button {
        min-width: 100px;
    }
`
const StyleModal = styled(Modal)`
    padding-bottom: 0;
    .ant-modal-content {
        display: flex;
        flex-direction: column;
        max-height: calc(100vh - 30px);
        max-width: calc(100vw - 30px);
    }
    .ant-modal-footer {
        padding: 0;
    }
    .ant-modal-title {
        position: relative;
    }
    .ant-modal-body {
        flex: 1;
        overflow: auto;
        padding: 20px;
    }
` as typeof Modal

const PageDialog: React.FC<PageDialogProps> = ({
    open,
    title,
    children,
    action,
    onClose,
    ...props
}) => {
    const normalTitle = (
        <>
            <strong>{title}</strong>
            <Button type="text" className="close" onClick={onClose}>
                x
            </Button>
        </>
    )
    // const Title = (
    //     <DialogTitle>
    //         {typeof title === 'string' ? normalTitle : title}
    //     </DialogTitle>
    // )
    // const Footer = action ? <DialogFooter>{action}</DialogFooter> : null
    return (
        <Modal
            centered
            closable={false}
            open={open}
            title={title}
            footer={action}
            {...props}
        >
            {children}
        </Modal>
    )
}

export default PageDialog
