import request from './request'
import {
    CustomersGroupPositionParams,
    MetadataDetailParams,
} from 'src/types/customers'


export const customersGroupPositionRequest = (data: CustomersGroupPositionParams) => {
    return request({
        url: '/customer-center/customer/data/basic/position',
        params: data,
        method: 'get'
    })
}

export const customersMedataEnumRequest = (data: MetadataDetailParams) => {
    return request({
        url: '/customer-center/query/meta/data/enum',
        data,
        method: 'post'
    })
}
