import React from 'react'
import { MobXProviderContext, useObserver } from 'mobx-react'

const useStores = () => {
    return React.useContext(MobXProviderContext)
}

const useStore = (storeName?: string, observer = false) => {
    const store = useStores()
    if (storeName && store[storeName] && observer) {
        return useObserver(() => store[storeName])
    }
    if (storeName && store[storeName] && !observer) {
        return store[storeName]
    }
    return useObserver(() => store)
}

export { useStore, useStores }