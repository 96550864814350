/**
 * 千位分隔符
 * @param n
 * @returns 10000.00 => 10,000.00;  10000 => 10,000.00
 */

interface options {
    name: string,
    val: string
}
export const thousandSeparators = (n: string | number) => {
    let num = n.toString().split('.'); // 分隔小数点
    let arr = num[0].split('').reverse(); // 转换成字符数组并且倒序排列
    let res = [];
    for (let i = 0, len = arr.length; i < len; i++) {
        if (i % 3 === 0 && i !== 0) {
            res.push(','); // 添加分隔符
        }
        res.push(arr[i]);
    }
    res.reverse(); // 再次倒序成为正确的顺序
    let resStr = '';
    if (num[1]) {
        // 如果有小数的话添加小数部分
        resStr = res.join('').concat('.' + num[1]);
    } else {
        resStr = res.join('')
    }
    return resStr;
};

// 导出文件
export function exportFile({fileData, fileName}: any) {
    const url = window.URL.createObjectURL(new Blob([fileData]))
    const link = document.createElement('a')
    link.style.display = 'none'
    link.href = url
    link.setAttribute('download', `${fileName}`)
    document.body.appendChild(link)
    link.click()
    URL.revokeObjectURL(link.href)
    document.body.removeChild(link)
}

// 获取uuid
export const getUUID = () => {
    const s: any = []
    const hexDigits = '0123456789abcdef'
    for (let i = 0; i < 32; i++) {
        s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1)
    }
    s[14] = '4' // bits 12-15 of the time_hi_and_version field to 0010
    s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1) // bits 6-7 of the clock_seq_hi_and_reserved to 01
    s[8] = s[13] = s[18] = s[23]

    const uuid = s.join('')
    return uuid
}

const setSession = (name: string, value: any) => {
    if (!name) return
    const values = JSON.stringify(value)
}

// session

export const stroage = {
    session: {
        set: function (name: string, value: any) {
            setSession(name, value)
            window.sessionStorage.setItem(name, value)
        },
        get: function (name: any) {
            if (!name) return;
            return window.sessionStorage.getItem(name)
        },

        remove: function (name: any) {
            if (!name) return;
            window.sessionStorage.removeItem(name)
        },

        clear: function () {
            localStorage.clear();
        }
    },
    local: {
        set: function (name: string, value: any) {
            setSession(name, value)
            window.localStorage.setItem(name, value)
        },
        get: function (name: any) {
            if (!name) return;
            if (name === 'userInfo') {
                return JSON.parse((window.localStorage.getItem(name)) as any)
            }
            return window.localStorage.getItem(name)
        },

        remove: function (name: any) {
            if (!name) return;
            window.localStorage.removeItem(name)
        },

        clear: function () {
            localStorage.clear();
        }
    }
}

// option转化
export const filterOptions = (arr: options[], unlimit = true) => {
    const unlessArrs = unlimit ? arr.push({ name: '不限', val: '' }) : arr
    return (arr || []).map((l: options) => {
        return { label: l.name, value: l.val }
    })
}

// 数值转化
export const translatePerformance = (type: string, num: number | string) => {
    if (Object.prototype.toString.call(num) === '[object Null]') return null
    switch (type) {
    case 'sum':
        return +num >= 100000
            ? `${(Number(num).toFixed(1))}`
            : `${(Number(num).toFixed(2))}`
    case 'amount':
        return Math.trunc(+num)
    case 'precent':
        return parseInt(String(num), 10) > 10 ? `${parseFloat(String(num)).toFixed(2)}` : `${parseFloat(String(num)).toFixed(1)}`
    case 'numerical':
        return Number(num) > 10 ? Number(num).toFixed(1) : Number(num).toFixed(2)
    }
}

// 防抖函数
// export function debounce (_this: any, func: Function, wait: number, ...rest: any[] ) {
//     let timer: any = null
//     return  () => {
//         let context = _this; // 注意 this 指向
//         let args = rest; // arguments中存着e
            
//         if (timer) clearTimeout(timer);
    
//         timer = setTimeout(() => {
//             func.apply(context, args)
//         }, wait)
//     }
// }

// export function debounce(fn: any, delay:number) {
//     let timer: any = null
//     const _debounce = function () {
//         if (timer) {
//             clearTimeout(timer)
//         }
//         timer = setTimeout(() => {
//             fn()
//         }, delay) }
//     return _debounce
// }
export function debounce (fn: any, time: number) {
    let timer:any
    return function(this: object, ...args: any[]) {
        clearTimeout(timer)
        timer = setTimeout(() => {
            fn.apply(this, args)
            clearTimeout(timer)
        }, time)
    }
}


export function hiddenTel(tel: string) {
    if (!tel) return '-'
    return tel.substring(0, 3)+"****"+ tel.substring(tel.length-4)
}
// 判断非null 非undefined值
export function filterData (str: any){
    return !str && str !== 0
}

// 判断null值
export function isNull (str: any){
    return !str && typeof str !== "undefined" && str !== 0
}
// 判断null后 显示-
export function showNull (str: any){
    if (!str && typeof str !== "undefined" && str !== 0) {
        return '-'
    } else {
        return str
    }
}
