import React, { useEffect, useState } from 'react'
import D from 'src/components/AntD'
import { Form } from 'antd'
import _ from 'underscore'

import { CustomerMetadata, AttributeMetadata } from 'src/views/taskManagement/customerMetadata'
import { getCustomerDicts } from 'src/api/screenCondition'

interface Iprops {
    customerMetadata: CustomerMetadata
    antForm: any
    index: number
    fieldIndex: number
    refresh: any
    isDetail: boolean
    i: number
}

const NumberComponent = ({ customerMetadata, antForm, index,i, fieldIndex, refresh, isDetail }: Iprops) => {
    const [options, setOptions] = useState([])
    const [loadings, setLoadings] = useState<boolean>(false)
    let timeout: ReturnType<typeof setTimeout> | null;
    const [page] = useState({
        pageNum: 1,
        pageSize: 200
    })

    const queryOptions = async (attributeMetadata: AttributeMetadata, value?: string) => {
        const {data, code} = await getCustomerDicts({
            uniqueId: attributeMetadata.uniqueId,
            search: value || null,
            page
        })
        const optionData = data.map((item: any) => ({
            label: item.display,
            value: item.value
        }))
        if (code === '00000') {
            setLoadings(true)
        }
        setOptions(optionData)
    }
    useEffect(() => {
        const attributeMetadata = customerMetadata.findAttributeMetadataByIndex(index, i)
        if (attributeMetadata.dictFlag === 1) {
            (async () => {
                await queryOptions(attributeMetadata)
            })()
        }
    }, [customerMetadata, index])

    const getSubOperatorComponent = () => {
        const attributeMetadata = customerMetadata.findAttributeMetadataByIndex(index, i)
        const operatorInfo = _.findWhere(attributeMetadata.operators || [], { operator: attributeMetadata.operatorValue })
        if (attributeMetadata.dictFlag === 1) {
            if (operatorInfo?.operator === 'BT') {
                return (
                    <div className='flex item-center'>
                        <D.Select value={attributeMetadata.extra && attributeMetadata.extra.start} onChange={(value) => {
                            const n = value
                            const extra = {
                                end: attributeMetadata.extra && attributeMetadata.extra.end || '',
                                start: n
                            }
                            const formData = customerMetadata.updateExtraValue(extra, index, i).getCustomerAttributeData()
                            antForm.getFieldValue('metadataForm')[index].metadataInnerForm[i] = formData[index][i]
                            antForm.setFieldsValue({
                                metadataForm: antForm.getFieldValue('metadataForm')
                            })
                        }} placeholder="请输入" disabled={isDetail} options={options} loading={!loadings && !isDetail} style={{ width: '83px' }} />
                        <span style={{ margin: '0 10px' }}>至</span>
                        <D.Select value={attributeMetadata.extra && attributeMetadata.extra.end} onChange={(value) => {
                            const n = value
                            const extra = {
                                start: attributeMetadata.extra && attributeMetadata.extra.start || '',
                                end: n
                            }
                            const formData = customerMetadata.updateExtraValue(extra, index, i).getCustomerAttributeData()
                            antForm.getFieldValue('metadataForm')[index].metadataInnerForm[i] = formData[index][i]
                            antForm.setFieldsValue({
                                metadataForm: antForm.getFieldValue('metadataForm')
                            })
                        }} placeholder="请输入" disabled={isDetail} options={options} loading={!loadings && !isDetail} style={{ width: '83px' }} />
                        {
                            attributeMetadata.displayUnit && <div className='metadata-unit'>{attributeMetadata.displayUnit}</div>
                        }
                    </div>
                )
            } else if (operatorInfo?.valueType === 'SINGLE' || operatorInfo?.valueType === 'MULTI') {
                return (
                    <div className='flex item-center'>
                        <D.Select value={attributeMetadata.extra && attributeMetadata.extra.value} onChange={(value) => {
                            if (!value?.length) queryOptions(attributeMetadata)
                            const extra = {
                                value
                            }
                            const formData = customerMetadata.updateExtraValue(extra, index, i).getCustomerAttributeData()
                            antForm.getFieldValue('metadataForm')[index].metadataInnerForm[i] = formData[index][i]
                            antForm.setFieldsValue({
                                metadataForm: antForm.getFieldValue('metadataForm')
                            })
                        }} onSearch={(value: string) => {
                            if (timeout) {
                                clearTimeout(timeout);
                                timeout = null;
                            }
                            timeout = setTimeout(() => {queryOptions(attributeMetadata, value)}, 800)}
                        }  placeholder="请选择" disabled={isDetail} options={options} loading={!loadings} style={{ width: 200 }} />
                        {
                            attributeMetadata.displayUnit && <div className='metadata-unit'>{attributeMetadata.displayUnit}</div>
                        }
                    </div>
                )
            }
            return <div />
        } else {
            if (operatorInfo?.operator === 'BT') {
                return (
                    <div className='flex item-center'>
                        <D.Input type="number" value={attributeMetadata.extra && attributeMetadata.extra.start} onChange={({ target: { value } }) => {
                            const n = value.replace(/[^0-9]/, '')
                            const extra = {
                                end: attributeMetadata.extra && attributeMetadata.extra.end || '',
                                start: n ? Number(n) : ''
                            }
                            const formData = customerMetadata.updateExtraValue(extra, index, i).getCustomerAttributeData()
                            antForm.getFieldValue('metadataForm')[index].metadataInnerForm[i] = formData[index][i]
                            antForm.setFieldsValue({
                                metadataForm: antForm.getFieldValue('metadataForm')
                            })
                        }} placeholder="请输入" disabled={isDetail} style={{ width: '83px' }} />
                        <span style={{ margin: '0 10px' }}>至</span>
                        <D.Input type="number" value={attributeMetadata.extra && attributeMetadata.extra.end} onChange={({ target: { value } }) => {
                            const n = value.replace(/[^0-9]/, '')
                            const extra = {
                                start: attributeMetadata.extra && attributeMetadata.extra.start || '',
                                end: n ? Number(n) : ''
                            }
                            const formData = customerMetadata.updateExtraValue(extra, index, i).getCustomerAttributeData()
                            antForm.getFieldValue('metadataForm')[index].metadataInnerForm[i] = formData[index][i]
                            antForm.setFieldsValue({
                                metadataForm: antForm.getFieldValue('metadataForm')
                            })
                        }} placeholder="请输入" disabled={isDetail} style={{ width: '83px' }} />
                        {
                            attributeMetadata.displayUnit && <div className='metadata-unit'>{attributeMetadata.displayUnit}</div>
                        }
                    </div>
                )
            } else if (operatorInfo?.valueType === 'SINGLE' || operatorInfo?.valueType === 'MULTI') {
                return (
                    <div className='flex item-center'>
                        <D.Input type="number" value={attributeMetadata.extra && attributeMetadata.extra.value} onChange={({ target: { value } }) => {
                            const extra = {
                                value
                            }
                            const formData = customerMetadata.updateExtraValue(extra, index, i).getCustomerAttributeData()
                            antForm.getFieldValue('metadataForm')[index].metadataInnerForm[i] = formData[index][i]
                            antForm.setFieldsValue({
                                metadataForm: antForm.getFieldValue('metadataForm')
                            })
                        }} placeholder="请输入" disabled={isDetail} style={{ width: 200 }}/>

                        {
                            attributeMetadata.displayUnit && <div className='metadata-unit'>{attributeMetadata.displayUnit}</div>
                        }

                    </div>
                )
            }
            return <div />
        }
    }
    
    const formList = [
        {
            label: '',
            name: 'queryName',
            children: <D.Select options={customerMetadata.getGroupOptions()} onChange={(value) => {
                const formData = customerMetadata.updateQueryNameByIndex(value as string, index, i).getCustomerAttributeData()
                antForm.getFieldValue('metadataForm')[index].metadataInnerForm[i] = formData[index][i]
                antForm.setFieldsValue({
                    metadataForm: antForm.getFieldValue('metadataForm')
                })
                const attribute = customerMetadata.findAttributeMetadataByIndex(index, i)
                if (attribute.dictFlag === 1) {
                    queryOptions(attribute)
                }
                refresh()
            }} allowClear={false} placeholder="请选择" disabled={isDetail} style={{ width: 200 }} />,
        },
        {
            label: '',
            name: 'operatorValue',
            children: <D.Select options={customerMetadata.getOperatorOption(index, i)} onChange={(value) => {
                const formData = customerMetadata.updateOperatorValueByIndex(value as string, index, i).getCustomerAttributeData()
                antForm.getFieldValue('metadataForm')[index].metadataInnerForm[i] = formData[index][i]
                antForm.setFieldsValue({
                    metadataForm: antForm.getFieldValue('metadataForm')
                })
            }} allowClear={false} placeholder="请选择" disabled={isDetail} style={{ width: 200 }} />,
        },
        {
            label: '',
            name: 'subOperatorValue',
            children: getSubOperatorComponent(),
        },
    ]

    return (
        formList.map((f, i) => (
            <Form.Item key={f.name} {...f} name={[fieldIndex, f.name]} />
        ))
    )
}
NumberComponent.indentify = 'NUMBER'

export default NumberComponent