import React, { Suspense, lazy } from 'react'
import { useRoutes, Link } from 'react-router-dom'
import BaseLayout from 'src/layout/BaseLayout'
import { asyncRoutes } from './routers'
import SvgIcon from 'src/components/SvgIcon/SvgIcon'


const lazyLoad = (src: any) => (
    <Suspense fallback={<div />}>{React.createElement(lazy(src))}</Suspense>
)

export const routes = [
    {
        path: '/',
        element: <BaseLayout />,
        children: [
            // {
            //     path: '',
            //     element: lazyLoad(
            //         () => import('src/views/dashboard/Welcome')
            //     ),
            //     meta: { title: '首页', icon: 'Home' },
            // },
            ...asyncRoutes,
        ],
    },
    {
        path: '/login',
        element: lazyLoad(() => import('src/views/login/index')),
    },

    {
        path: '*',
        element: <NoMatch />,
    },
]

// const Routers = () => {
//     return <>{useRoutes(routes)}</>
// }

function NoMatch() {
    return (
        <div>
            <h2>It looks like you're lost...</h2>
            <p>
                <Link to="/">Go to the home page</Link>
            </p>
        </div>
    )
}

// export default Routers
