import React, { useEffect, useState, useMemo, useRef } from 'react'
import D from 'src/components/AntD'
import { Form, Button, DatePicker, Space, Radio, RadioChangeEvent } from 'antd'
import _ from 'underscore'
import debounce from 'lodash/debounce'

// import { CustomerMetaOrderdata, AttributeMetadata } from 'src/views/taskManagement/CustomerMetaOrderdata'
import { CustomerMetaOrderdata, AttributeMetadata } from 'src/views/taskManagement/customerMetaOrderdata'

import { getOrderDicts } from 'src/api/screenCondition'

interface Iprops {
    CustomerMetaOrderdata: CustomerMetaOrderdata
    antForm: any
    index: number
    fieldIndex: number
    orderIndex:number
    isDetail: boolean
}

const ArrayComponent = ({ CustomerMetaOrderdata, antForm,orderIndex, index, fieldIndex, isDetail }: Iprops) => {
    const [options, setOptions] = useState([])
    const [loadings, setLoadings] = useState<boolean>(false)
    let timeout: ReturnType<typeof setTimeout> | null;
    const [page] = useState({
        pageNum: 1,
        pageSize: 200
    })

    const queryOptions = async (attributeMetadata: AttributeMetadata, value?: string) => {
        const { data, code } = await getOrderDicts({
            uniqueId: attributeMetadata.uniqueId,
            search: value || null,
            page
        })
        const optionData = data.map((item: any) => ({
            label: item.display,
            value: item.value
        }))
        if (code === '00000') {
            setLoadings(true)
            setOptions(optionData)
        }
    }

    useEffect(() => {
        const attributeMetadata = CustomerMetaOrderdata.findAttributeMetadataByIndex(orderIndex,index)
        if (attributeMetadata.dictFlag === 1) {
            (async () => {
                await queryOptions(attributeMetadata)
            })()
        }
    }, [CustomerMetaOrderdata])

    const getSubOperatorComponent = () => {
        const attributeMetadata = CustomerMetaOrderdata.findAttributeMetadataByIndex(orderIndex,index)
        const operatorInfo = _.findWhere(attributeMetadata.operators || [], { operator: attributeMetadata.operatorValue })
        if (operatorInfo?.valueType === 'MULTI') {
            return <D.Select mode='multiple' value={attributeMetadata.extra && attributeMetadata.extra.value} onChange={(value) => {
                if (!value.length) queryOptions(attributeMetadata)
                const extra = {
                    value
                }
                const formData = CustomerMetaOrderdata.updateExtraValue(extra,orderIndex, index).getCustomerAttributeData()
                
                antForm.getFieldValue('property')[orderIndex].metadataOrderForm[index] = formData[orderIndex][index]
                // fix 下拉选择多个的时候没有填充进去下拉框 所以要吧subOperatorValue = value
                if (Array.isArray(antForm.getFieldValue('property')[orderIndex].metadataOrderForm[index].subOperatorValue)) {
                    antForm.getFieldValue('property')[orderIndex].metadataOrderForm[index].subOperatorValue = value
                }
                antForm.setFieldsValue({
                    property: antForm.getFieldValue('property')
                })
                // const attributeMetadata = CustomerMetaOrderdata.findAttributeMetadataByIndex(orderIndex,index)
            }} onSearch={(value: string) => {
                if (timeout) {
                    clearTimeout(timeout);
                    timeout = null;
                }
                timeout = setTimeout(() => {queryOptions(attributeMetadata, value)}, 800)}}  placeholder="请选择" disabled={isDetail}  options={options} style={{ width: 200 }} loading={!loadings && !isDetail}/>
        } else if (operatorInfo?.valueType === 'SINGLE') {
            return <D.Select value={attributeMetadata.extra && attributeMetadata.extra.value} onChange={(value) => {
                const extra = {
                    value
                }
                const formData = CustomerMetaOrderdata.updateExtraValue(extra,orderIndex, index).getCustomerAttributeData()
                antForm.getFieldValue('property')[orderIndex].metadataOrderForm[index] = formData[orderIndex][index]
                antForm.setFieldsValue({
                    property: antForm.getFieldValue('property')
                })
            }} onSearch={(value: string) => {
                if (timeout) {
                    clearTimeout(timeout);
                    timeout = null;
                }
                timeout = setTimeout(() => {queryOptions(attributeMetadata, value)}, 800)}} placeholder="请选择" disabled={isDetail} options={options} loading={!loadings && !isDetail}/>
        }
        return <div />
    }
    
    const formList = [
        {
            label: '',
            name: 'queryName',
            children: <D.Select options={CustomerMetaOrderdata.getGroupOptions()} onChange={(value) => {
                const formData = CustomerMetaOrderdata.updateQueryNameByIndex(value as string,orderIndex, index).getCustomerAttributeData()
                // antForm.setFieldsValue({
                //     metadataOrderForm: formData
                // })
                antForm.getFieldValue('property')[orderIndex].metadataOrderForm[index] = formData[orderIndex][index]
                antForm.setFieldsValue({
                    property: antForm.getFieldValue('property')
                })
                const attribute = CustomerMetaOrderdata.findAttributeMetadataByIndex(orderIndex,index)
                if (attribute.dictFlag === 1) {
                    queryOptions(attribute)
                }
            }} allowClear={false} placeholder="请选择" disabled={isDetail} style={{ width: 150 }} />,
        },
        {
            label: '',
            name: 'operatorValue',
            children: <D.Select options={CustomerMetaOrderdata.getOperatorOption(orderIndex,index)} onChange={(value) => {
                const formData = CustomerMetaOrderdata.updateOperatorValueByIndex(value as string,orderIndex, index).getCustomerAttributeData()
                antForm.getFieldValue('property')[orderIndex].metadataOrderForm[index] = formData[orderIndex][index]
                antForm.setFieldsValue({
                    property: antForm.getFieldValue('property')
                })

            }} allowClear={false} placeholder="请选择" disabled={isDetail} style={{ width: 150 }} />,
        },
        {
            label: '',
            name: 'subOperatorValue',
            children: getSubOperatorComponent(),
        },
    ]

    return (
        formList.map((f, i) => (
            <Form.Item key={f.name} {...f} name={[fieldIndex, f.name]} />
        ))
    )
}
ArrayComponent.indentify = 'ARRAY'

export default ArrayComponent