
import request from './request'

export const getCustomer = async () => {
    return request({
        url: '/core-data-service/metadata/query/customer',
        method: 'get',
    });
};

export const getCustomerOrder = async () => {
    return request({
        url: '/core-data-service/metadata/query/order',
        method: 'get',
    });
};

export const getCustomerDicts = async (data: any) => {
    return request({
        url: '/core-data-service/metadata/query/dict/customer',
        method: 'post',
        data
    })
}

export const getOrderDicts = async (data: any) => {
    return request({
        url: '/core-data-service/metadata/query/dict/order',
        method: 'post',
        data
    })
}

// 属性操作符计算
export const getTooltipContent = async (data: any) => {
    return request({
        url: '/core-data-service/metadata/operator/calculate/customer',
        method: 'post',
        data
    })
}

// 工单操作符计算
export const getTooltipContentOrder = async (data: any) => {
    return request({
        url: '/core-data-service/metadata/operator/calculate/order',
        method: 'post',
        data
    })
}

// 查看分组信息
export const examineGroupInfomation = async (data: any) => {
    return request({
        url: '/core-data-service/group/info',
        params: data,
        method: 'get',
    })
}
