import React from 'react'
import Group from 'antd/lib/input/Group'
import Input, { InputProps, InputRef } from 'antd/lib/input/Input'
import Password from 'antd/lib/input/Password'
import Search from 'antd/lib/input/Search'
import TextArea from 'antd/lib/input/TextArea'

const DefaultInput = (props: InputProps, ref: React.ForwardedRef<InputRef>) => {
    return (
        <Input ref={ref} autoComplete="off" allowClear {...props}>
            {props.children}
        </Input>
    )
}

const inputRef = React.forwardRef(DefaultInput)

type InternalInputType = typeof inputRef

interface InputInterface extends InternalInputType {
    Group: typeof Group
    Search: typeof Search
    TextArea: typeof TextArea
    Password: typeof Password
}

const DInput = inputRef as InputInterface

DInput.Group = Group
DInput.Search = Search
DInput.TextArea = TextArea
DInput.Password = Password

export default DInput
