import React, { useEffect, useState, useMemo, useRef } from 'react'
import D from 'src/components/AntD'
import { Form, Button, DatePicker, Space, Radio, RadioChangeEvent } from 'antd'
import _ from 'underscore'

import { CustomerMetaOrderdata, AttributeMetadata } from 'src/views/taskManagement/customerMetaOrderdata'
import { getOrderDicts } from 'src/api/screenCondition'

interface Iprops {
    CustomerMetaOrderdata: CustomerMetaOrderdata
    antForm: any
    index: number
    fieldIndex: number
    orderIndex: number
    isDetail: boolean
}

const StringComponent = ({ CustomerMetaOrderdata, antForm, index, fieldIndex, orderIndex, isDetail }: Iprops) => {
    const [loadings, setLoadings] = useState<boolean>(false)
    const [options, setOptions] = useState([])
    let timeout: ReturnType<typeof setTimeout> | null;
    const [page] = useState({
        pageNum: 1,
        pageSize: 200
    })
    const queryOptions = async (attributeMetadata: AttributeMetadata, value?: string) => {
        const { data, code } = await getOrderDicts({
            uniqueId: attributeMetadata.uniqueId,
            search: value || null,
            page
        })
        const optionData = data.map((item: any) => ({
            label: item.display,
            value: item.value
        }))
        if (code === '00000') {
            setLoadings(true)
            setOptions(optionData)
        }
    }
    useEffect(() => {
        const attributeMetadata = CustomerMetaOrderdata.findAttributeMetadataByIndex(orderIndex,index)
        if (attributeMetadata.dictFlag === 1) {
            (async () => {
                await queryOptions(attributeMetadata)
            })()
        }
    }, [CustomerMetaOrderdata])

    // todo dictFlag 为1 需要另外请求下拉接口  第三个ui都是要改成select
    const getSubOperatorComponent = () => {
        const attributeMetadata = CustomerMetaOrderdata.findAttributeMetadataByIndex(orderIndex, index)
        const operatorInfo = _.findWhere(attributeMetadata.operators || [], { operator: attributeMetadata.operatorValue })
        if (attributeMetadata.dictFlag === 1) {
            if (operatorInfo?.valueType === 'MULTI') {
                return <div>
                    <D.Select mode='multiple' value={attributeMetadata.extra && attributeMetadata.extra.value} onChange={(value) => {
                        if (!value.length) queryOptions(attributeMetadata)
                        const extra = {
                            value
                        }
                        const formData = CustomerMetaOrderdata.updateExtraValue(extra,orderIndex, index).getCustomerAttributeData()
                        antForm.getFieldValue('property')[orderIndex].metadataOrderForm[index] = formData[orderIndex][index]
                        // 切换选项
                        if (Array.isArray(antForm.getFieldValue('property')[orderIndex].metadataOrderForm[index].subOperatorValue)) {
                            antForm.getFieldValue('property')[orderIndex].metadataOrderForm[index].subOperatorValue = value
                        }
                        antForm.setFieldsValue({
                            property: antForm.getFieldValue('property')
                        })

                    }} onSearch={(value: string) => {
                        if (timeout) {
                            clearTimeout(timeout);
                            timeout = null;
                        }
                        timeout = setTimeout(() => {queryOptions(attributeMetadata, value)}, 800)}
                    } placeholder="请选择" disabled={isDetail} options={options} style={{ width: 200 }} loading={!loadings && !isDetail}/>
                </div>
            } else if (operatorInfo?.valueType === 'SINGLE') {
                return <D.Select value={attributeMetadata.extra && attributeMetadata.extra.value} onChange={({ target: { value } }) => {
                    const extra = {
                        value
                    }
                    const formData = CustomerMetaOrderdata.updateExtraValue(extra,orderIndex, index).getCustomerAttributeData()
                    antForm.getFieldValue('property')[orderIndex].metadataOrderForm[index] = formData[orderIndex][index]
                    antForm.setFieldsValue({
                        property: antForm.getFieldValue('property')
                    })
                }} onSearch={(value: string) => {
                    if (timeout) {
                        clearTimeout(timeout);
                        timeout = null;
                    }
                    timeout = setTimeout(() => {queryOptions(attributeMetadata, value)}, 800)}
                } placeholder="请选择" disabled={isDetail}  options={options} style={{ width: 200 }} loading={!loadings && !isDetail}/>
            }
            return null
        } else {
            if (operatorInfo?.valueType === 'MULTI') {
                return <div>
                    <D.Select mode='tags' value={attributeMetadata.extra && attributeMetadata.extra.value} onChange={(value) => {
                        const extra = {
                            value
                        }
                        const formData = CustomerMetaOrderdata.updateExtraValue(extra,orderIndex, index).getCustomerAttributeData()
                        antForm.getFieldValue('property')[orderIndex].metadataOrderForm[index] = formData[orderIndex][index]
                        // 切换选项
                        if (Array.isArray(antForm.getFieldValue('property')[orderIndex].metadataOrderForm[index].subOperatorValue)) {
                            antForm.getFieldValue('property')[orderIndex].metadataOrderForm[index].subOperatorValue = value
                        }
                        antForm.setFieldsValue({
                            property: antForm.getFieldValue('property')
                        })
                    }} placeholder="请输入" disabled={isDetail}  open={false} style={{ width: 200 }} />
                </div>
            } else if (operatorInfo?.valueType === 'SINGLE') {
                return <D.Input value={attributeMetadata.extra && attributeMetadata.extra.value} onChange={({ target: { value } }) => {
                    const extra = {
                        value
                    }
                    const formData = CustomerMetaOrderdata.updateExtraValue(extra,orderIndex, index).getCustomerAttributeData()
                    antForm.getFieldValue('property')[orderIndex].metadataOrderForm[index] = formData[orderIndex][index]
                    antForm.setFieldsValue({
                        property: antForm.getFieldValue('property')
                    })
                }} placeholder="请输入" disabled={isDetail} style={{ width: 200 }}/>
            }
            return null
        }
    }
    
    const formList = [
        {
            label: '',
            name: 'queryName',
            children: <D.Select  options={CustomerMetaOrderdata.getGroupOptions()} onChange={(value) => {
                const formData = CustomerMetaOrderdata.updateQueryNameByIndex(value as string,orderIndex, index).getCustomerAttributeData()
                const attribute = CustomerMetaOrderdata.findAttributeMetadataByIndex(orderIndex,index)
                if (attribute.dictFlag === 1) {
                    queryOptions(attribute)
                }
                antForm.getFieldValue('property')[orderIndex].metadataOrderForm[index] = formData[orderIndex][index]
                if (typeof( antForm.getFieldValue('property')[orderIndex].metadataOrderForm[index].subOperatorValue) === 'undefined') {
                    antForm.getFieldValue('property')[orderIndex].metadataOrderForm[index].subOperatorValue = []
                }
                antForm.setFieldsValue({
                    property: antForm.getFieldValue('property')
                })
            }} allowClear={false} placeholder="请选择" disabled={isDetail} style={{ width: 150 }} />,
        },
        {
            label: '',
            name: 'operatorValue',
            children: <D.Select options={CustomerMetaOrderdata.getOperatorOption(orderIndex, index)} onChange={(value) => {
                const formData = CustomerMetaOrderdata.updateOperatorValueByIndex(value as string,orderIndex, index).getCustomerAttributeData()
                antForm.getFieldValue('property')[orderIndex].metadataOrderForm[index] = formData[orderIndex][index]
                antForm.setFieldsValue({
                    property: antForm.getFieldValue('property')
                })

            }} allowClear={false} placeholder="请选择" disabled={isDetail} style={{ width: 150 }} />,
        },
        {
            label: '',
            name: 'subOperatorValue',
            children: getSubOperatorComponent(),
        },
    ]

    return (
        formList.map((f, i) => (
            f.children && <Form.Item key={f.name} {...f} name={[fieldIndex, f.name]} />
        ))
    )
}
StringComponent.indentify = 'STRING'

export default StringComponent