import React from 'react'
import { Outlet } from 'react-router-dom'
import HeaderNav from './HeaderNav/index'
import SideMenu from './SideMenu'
import styles from './index.module.less'
import styled from 'styled-components'
import Authorization from 'src/authorization'

const Main = styled.div`
    flex: 1;
    height: 100%;
    overflow-y: auto;
    overflow-y: overlay;
    padding: 18px 20px 20px 20px;
    background: #f8f9fc;
    position: relative;
    & > div {
        min-height: 100%;
        position: relative;
    }
`

const BaseLayout: React.FC = () => {
    return (
        <Authorization>
            <HeaderNav />
            <div className={styles['layout-contain']}>
                <SideMenu />
                <Main>
                    <Outlet />
                </Main>
            </div>
        </Authorization>
    )
}

export default BaseLayout
