import React, { useEffect, useState, useMemo, useRef } from 'react'
import D from 'src/components/AntD'
import { Form, Button, DatePicker, Space, Radio, RadioChangeEvent } from 'antd'
import _ from 'underscore'
import debounce from 'lodash/debounce'

import { CustomerMetaOrderdata, AttributeMetadata } from 'src/views/taskManagement/customerMetaOrderdata'

import { getOrderDicts } from 'src/api/screenCondition'

interface Iprops {
    CustomerMetaOrderdata: CustomerMetaOrderdata
    antForm: any
    index: number
    fieldIndex: number
    orderIndex: number
    isDetail: boolean
}

const BooleanComponent = ({ CustomerMetaOrderdata, antForm,orderIndex, index, fieldIndex, isDetail }: Iprops) => {
    
    const formList = [
        {
            label: '',
            name: 'queryName',
            children: <D.Select options={CustomerMetaOrderdata.getGroupOptions()} onChange={(value) => {
                const formData = CustomerMetaOrderdata.updateQueryNameByIndex(value as string,orderIndex, index).getCustomerAttributeData()
                antForm.getFieldValue('property')[orderIndex].metadataOrderForm[index] = formData[orderIndex][index]
                antForm.setFieldsValue({
                    property: antForm.getFieldValue('property')
                })
            }} allowClear={false} placeholder="请选择" disabled={isDetail} style={{ width: 150 }} />,
        },
        {
            label: '',
            name: 'operatorValue',
            children: <D.Select options={CustomerMetaOrderdata.getOperatorOption(orderIndex,index)} onChange={(value) => {
                const formData = CustomerMetaOrderdata.updateOperatorValueByIndex(value as string,orderIndex, index).getCustomerAttributeData()
                antForm.getFieldValue('property')[orderIndex].metadataOrderForm[index] = formData[orderIndex][index]
                antForm.setFieldsValue({
                    property: antForm.getFieldValue('property')
                })

            }} allowClear={false} placeholder="请选择" disabled={isDetail} style={{ width: 150 }} />,
        }
    ]

    return (
        formList.map((f, i) => (
            <Form.Item key={f.name} {...f} name={[fieldIndex, f.name]} />
        ))
    )
}
BooleanComponent.indentify = 'BOOLEAN'

export default BooleanComponent