import React, { useEffect, useState } from 'react'
import { matchRoutes, useLocation } from 'react-router'
import type { MenuProps, MenuTheme } from 'antd'
import { Menu, Switch } from 'antd'
import { routes } from 'src/routers'
import { NavLink } from 'react-router-dom'
import SMenu from './SMenu'
import store from 'src/store'

import {
    AppstoreOutlined,
    MailOutlined,
    SettingOutlined,
} from '@ant-design/icons'

type MenuItem = Required<MenuProps>['items'][number]
// import useStores from 'src/stores'
// import SvgIcon from 'src/views/components/SvgIcon'
import styles from '../index.module.less'
// import SMenu from './SMenu'

interface routerItem {
    name: string
    path: string
    children: routerItem[]
}

const SideMenu: React.FC = ({ ...props }) => {
    // const { User } = useStores()
    const location = useLocation()
    const [collapsed, setCollapsed] = useState(false)
    const [sideMenu, setSideMenu] = useState<any[]>([])
    const [activeMenu, setActiveMenu] = useState<any[]>([])
    const [current, setCurrent] = useState('1')
    const { getUser, user, menuTree } = store.userStore

    const getSubMenu = (list: any[]) => {
        return list[0].route.children.map((item: any) => {
            const hasC = !item.children
            // const menuUrl = item.fullpath
            // const menuName = item.meta.title
            // const { menuUrl, menuName } = item.menu
            const { path } = item
            return hasC ? (
                <Menu.Item
                    key={path}
                    // icon={meta && meta.icon ? Icon(meta.icon) : null}
                >
                    <NavLink to={path}>{item.meta.name}</NavLink>
                </Menu.Item>
            ) : (
                <Menu.SubMenu
                    key={path}
                    title={item.meta.name}
                    // icon={meta && meta.icon ? Icon(meta.icon) : null}
                    popupClassName="side-menu-popup"
                >
                    {getSubMenu(item.children)}
                </Menu.SubMenu>
            )
        })
    }

    useEffect(() => {
        const matches = matchRoutes(menuTree, location.pathname) // 返回匹配到的路由数组对象，每一个对象都是一个路由对象
        const sm = (matches && matches[0].route.children) || []
        setSideMenu(sm)
        const invisible = matches === null || (matches![matches.length - 1] as any).route.hidden
        const am = (matches || []).map(m => m.pathname)
        setActiveMenu(invisible ? [] : am)
        // // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location])

    return (
        <div className={styles['side-menu-wrapper']}>
            {/* <div className="side-btn" >
				<SvgIcon name={collapsed ? 'side-expand' : 'side-collapse'} onClick={() => setCollapsed(!collapsed)}/>
			</div> */}
            <SMenu
                sideMenu={sideMenu}
                activeMenu={activeMenu}
                collapsed={collapsed}
            />
            {/* <SMenu sideMenu={sideMenu} activeMenu={activeMenu} collapsed={collapsed}  /> */}
        </div>
    )
}

export default SideMenu
