import React, { Suspense, lazy } from 'react'
import SvgIcon from 'src/components/SvgIcon/SvgIcon'

const lazyLoad = (src: any) => (
    <Suspense fallback={<>...</>}>{React.createElement(lazy(src))}</Suspense>
)

export const asyncRoutes = [
    {
        path: '/dashboard',
        meta: { title: '首页',icon: 'dashboard' },
        element: lazyLoad(
            () => import('src/views/dashboard')
        ),
    },
    {
        path: '/manage',
        meta: { title: '经营概览', icon: 'Block' },
        children: [
            {
                path: '/manage/output-gross',
                element: lazyLoad(() => import('src/views/manage/outputGross')),
                meta: { title: '产值毛利' },
            },
            
            {
                path: '/manage/buinese-rebate',
                element: lazyLoad(
                    () => import('src/views/manage/buineseRebate')
                ),
                meta: { title: '商政返利', icon: '' },
            },
            // {
            //     path: '/manage/sales-rebate',
            //     element: lazyLoad(
            //         () => import('src/views/manage/salesRebate')
            //     ),
            //     meta: { title: '销售返利', icon: '' },
            // },
            // {
            //     path: '/manage/basic-disc',
            //     element: lazyLoad(
            //         () => import('src/views/manage/basicDisc')
            //     ),
            //     meta: { title: '客户基盘', icon: '' },
            // },
        ],
    },
    {
        path: '/agility',
        meta: { title: '敏捷分析', icon: 'FundProjectionScreen' },
        children: [
            {
                path: '/agility/production-rebete',
                element: lazyLoad(
                    () => import('src/views/agilityAnalyze/productionRebete')
                ),
                meta: { title: '产值毛利短板', icon: '' },
            },
            {
                path: '/agility/buiness-rebete',
                element: lazyLoad(
                    () => import('src/views/agilityAnalyze/buinessRebete')
                ),
                meta: { title: '商政返利短板', icon: '' },
            },
            {
                path: '/agility/basic-rebete',
                element: lazyLoad(
                    () => import('src/views/agilityAnalyze/basicRebete')
                ),
                meta: { title: '客户基盘短板', icon: '' },
            }
        ],
    },
    {
        path: '/task',
        meta: { title: '任务管理',icon: 'Partition' },
        children: [
            {
                meta: { title: '客户信息管理', icon: '' },

                path: '/task/client-manage',
                element: lazyLoad(
                    () => import('src/views/taskManagement/clientInformation')
                ),
            },
            {
                meta: { title: '客户信息详情', icon: '' },
                path: '/task/client-manage/:id',
                hidden: true,
                element: lazyLoad(
                    () =>
                        import(
                            'src/views/taskManagement/clientInformation/detail'
                        )
                ),
            },
            {
                meta: { title: '客户分组信息管理', icon: '' },
                hidden: true,
                path: '/task/customer-group-info/:groupUid',
                element: lazyLoad(
                    () => import('src/views/taskManagement/customerGroupInfo')
                ),
            },
            {
                meta: { title: '客户分组管理', icon: '' },
                path: '/task/client-grouping',
                element: lazyLoad(
                    () => import('src/views/taskManagement/clientGrouping')
                ),
            },
            {
                meta: { title: '筛选条件创建分组', icon: '' },
                path: '/task/client-grouping/create-screen',
                hidden: true,
                element: lazyLoad(
                    () =>
                        import(
                            'src/views/taskManagement/clientGrouping/customize/ScreenCondition'
                        )
                ),
            },
            {
                meta: { title: '数据导入创建分组', icon: '' },
                path: '/task/client-grouping/data-import',
                hidden: true,
                element: lazyLoad(
                    () =>
                        import(
                            'src/views/taskManagement/clientGrouping/customize/DataImport'
                        )
                ),
            },
            {
                path: '/task/manager-active',
                element: lazyLoad(
                    () => import('src/views/discountManage/index')
                ),
                meta: { title: '优惠活动管理', icon: '' },
            },
            {
                meta: { title: '客户推荐任务', icon: '' },
                path: '/task/recommend',
                element: lazyLoad(
                    () =>
                        import(
                            'src/views/taskManagement/recommendTask'
                        )
                ),
            },
            {
                meta: { title: '创建任务', icon: '' },
                path: '/task/recommend/createTask',
                hidden: true,
                element: lazyLoad(
                    () =>
                        import(
                            'src/views/taskManagement/recommendTask/createRecommendTask'
                        )
                ),
            },
        ],
    },
    {
        path: '/accessories',
        meta: { title: '数据统计',icon: 'active-manage' },
        children: [
            {
                path: '/accessories/reception',
                element: lazyLoad(
                    () => import('src/views/reception')
                ),
                meta: { title: '接待建议统计', icon: '' },
            },
            {
                path: '/accessories/statistics',
                element: lazyLoad(
                    () => import('src/views/accessories')
                ),
                meta: { title: '配件推荐统计', icon: '' },
            },
            {
                path: '/accessories/recommend',
                element: lazyLoad(
                    () => import('src/views/recommendTaskStatistics')
                ),
                meta: { title: '推荐任务统计', icon: '' },
            },
        ],
    },
    {
        path: '/rules',
        meta: { title: '规则设置',icon: 'Group' },
        children: [
            {
                path: '/rules/tag-management',
                element: lazyLoad(
                    () => import('src/views/rulesSetting/tagsManage')
                ),
                meta: { title: '标签管理', icon: '' },
            },
            {
                path: '/rules/reception-rule',
                element: lazyLoad(
                    () => import('src/views/rulesSetting/receptionRule')
                ),
                meta: { title: '接待管理', icon: '' },
            },
        ],
    },
    {
        path: '/system',
        meta: { title: '系统管理',icon: 'DeploymentUnit' },
        children: [
            {
                path: '/system/user-list',
                element: lazyLoad(
                    () => import('src/views/userCenter/userList')
                ),
                meta: { title: '用户管理', icon: '' },
            },
            {
                path: '/system/role-list',
                element: lazyLoad(
                    () => import('src/views/userCenter/roleList')
                ),
                meta: { title: '角色管理', icon: '' },
            },
        ],
    }
]
