import { observable, action, makeAutoObservable, computed } from 'mobx';

import {
    customersMedataEnumRequest,
    customersGroupPositionRequest
} from 'src/api/customer'


// types
import {
    CustomersMetadataEnum,
    MetadataDetailParams,
    CustomersGroupPositionParams,
} from '../../types/customers';


export class CustomersStore {
    @observable
        customersEnumMetadata: CustomersMetadataEnum = {};

    constructor() {
        makeAutoObservable(this, {}, { autoBind: true })
    }
    

    @action
    async customersGroupPositionRequest({ name }: CustomersGroupPositionParams) {
        const response = await customersGroupPositionRequest({
            name,
        });
        return response;
    }

    @action
    async customersMedataEnumRequest({ queryCode }: MetadataDetailParams) {
        // const response = await customersMedataEnumRequest({
        // });
        // if (response.code === '00000') {
        //     this.customersEnumMetadata[queryCode] = response.data;
        // }
    }

}
export default CustomersStore

