import StringComponent from './StringComponent'
import NumberComponent from './NumberComponent'
import DateComponent from './DateComponent'
import DateTimeComponent from './DateTimeComponent'
import ArrayComponent from './ArrayComponent'
import BooleanComponent from './BooleanComponent'
const metadataOrderComponents = new Map();

const registerComponent = (metadataComponent: any) => {
    const type = metadataComponent.indentify;
    if (type !== null) {
    	metadataOrderComponents.set(type, metadataComponent);
    }
}

export { metadataOrderComponents };
export default function orderRegister() {
    registerComponent(StringComponent)
    registerComponent(NumberComponent)
    registerComponent(DateComponent)
    registerComponent(DateTimeComponent)
    registerComponent(ArrayComponent)
    registerComponent(BooleanComponent)

    // registerComponent(RelativeCurrentTimeComponent);
    // registerComponent(RangeAndSingleNumberComponent);
    // registerComponent(RelativeFixTimeComponent);
    // registerComponent(DistrictComponent);
    // registerComponent(NumberComponent);
    // registerComponent(EnumComponent);
    // registerComponent(PeriodDateComponent);
    // registerComponent(PercentComponent);
}