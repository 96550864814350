import React, { useEffect, useState, useMemo, useRef } from 'react'
import D from 'src/components/AntD'
import { Form, Button, DatePicker, Space, Radio, RadioChangeEvent } from 'antd'
import _ from 'underscore'
import debounce from 'lodash/debounce'

import { CustomerMetadata, AttributeMetadata } from 'src/views/taskManagement/customerMetadata'

import { getCustomerDicts } from 'src/api/screenCondition'

interface Iprops {
    customerMetadata: CustomerMetadata
    antForm: any
    index: number
    fieldIndex: number
    refresh: any
    isDetail: boolean
    i: number
}

const BooleanComponent = ({ customerMetadata, antForm, index,i, fieldIndex, refresh,isDetail }: Iprops) => {
    
    const formList = [
        {
            label: '',
            name: 'queryName',
            children: <D.Select options={customerMetadata.getGroupOptions()} onChange={(value) => {
                const formData = customerMetadata.updateQueryNameByIndex(value as string, index, i).getCustomerAttributeData()
                antForm.getFieldValue('metadataForm')[index].metadataInnerForm[i] = formData[index][i]
                antForm.setFieldsValue({
                    metadataForm: antForm.getFieldValue('metadataForm')
                })
                refresh()
            }} allowClear={false} placeholder="请选择"  disabled={isDetail} style={{ width: 200 }} />,
        },
        {
            label: '',
            name: 'operatorValue',
            children: <D.Select options={customerMetadata.getOperatorOption(index, i)} onChange={(value) => {
                const formData = customerMetadata.updateOperatorValueByIndex(value as string, index, i).getCustomerAttributeData()
                antForm.getFieldValue('metadataForm')[index].metadataInnerForm[i] = formData[index][i]
                antForm.setFieldsValue({
                    metadataForm: antForm.getFieldValue('metadataForm')
                })

            }} allowClear={false} placeholder="请选择"  disabled={isDetail} style={{ width: 200 }} />,
        }
    ]

    return (
        formList.map((f, i) => (
            <Form.Item key={f.name} {...f} name={[fieldIndex, f.name]} />
        ))
    )
}
BooleanComponent.indentify = 'BOOLEAN'

export default BooleanComponent