import { observable, action, makeAutoObservable } from 'mobx';

// types
import { Notify, ActiveNav } from '../../types/common';

export class CommonStore {
    @observable
        notify: Notify = {
            message: '',
            type: 'success',
            open: false,
        };

    @observable
        activeNav: ActiveNav | string = 'dashboard';

    constructor() {
        makeAutoObservable(this);
    }
    @action
    setNotify(notify: Notify) {
        this.notify = notify;
    }

    @action
    setActiveNav(active: ActiveNav | string) {
        this.activeNav = active;
    }

    // @computed
    // get getCarInfo() {
    // 	return this.car;
    // }
}
