import {
    observable,
    action,
    // computed,
    // makeObservable,
    makeAutoObservable,
} from 'mobx';

import {
    loginRequest,
    userRequest,
    mobileMessageRequest,
    loginRequestByMobile,
    mobileForgetRequest,
    passwordResetRequest,
    logout,
} from 'src/api/login';

// types
import {
    LoginParam,
    UserInfoParam,
    MobileMessageParam,
    LoginByMobileParam,
    MobileForgetParam,
    PasswordResetParam,
} from 'src/types/login';
import { AnyObject } from 'yup/lib/types';

interface userListInfo {
    name: string;
    storeUid: string,
    accountId: string,
    brandId: string,
    groupId: string,
    mobile: string,
    roleId: string,
    uid: string
}

// interface userInfo {}
interface storeInfoList {
    tenantUid:''
    tenantName: ''
    brandId: ''
    brandName:''
    storeUid:''
    storeName: ''
}

interface User {
    authCode: string,
    storeInfoList: storeInfoList[],
    tenantInfoList: []
    accountType: string,
    mobile: string
    permissionItemList?: any[]
}
interface IItemDict {
   key: string 
}

export class UserStore {
    @observable
        cache = 'abcsd';

    @observable
    public user: User = {
            authCode: '',
            storeInfoList: [],
            tenantInfoList: [],
            accountType: '',
            mobile: '',
        };

    @observable
    public menuTree: any = []

    @observable
    public userSingleInfo:  { [key: string]: any } = { };    
    constructor() {
        makeAutoObservable(this, {}, { autoBind: true })
    }

    @action
    setMenuTree (tree: any) {
        this.menuTree = tree
    }

    @action
    getMenuTree () {
        return this.menuTree
    }
    
    // @observable
    // isAuto: boolean = false;

    // @action
    // setName(name: string) {
    // 	this.user.name = name;
    // }
    @action
    getUser (user: User) {
        this.user = user
    }

    // @action
    // getUserInfo(userInfo:userInfo) {
    //     this.userInfo = userInfo
    //     console.log('this.userInfo', this.userInfo)
    // }

    @action
    getUserSingleInfo(userSingleInfo:userListInfo) {
        this.userSingleInfo = userSingleInfo
        console.log('this.userSingleInfo', this.userSingleInfo)
    }

    @action clearOut () {
        this.user= {
            authCode: '',
            storeInfoList: [],
            tenantInfoList: [],
            accountType: '',
            mobile: '',
        };
        this.menuTree = []
    }

    @action
    async login({ mobile, password }: LoginParam) {
        return await loginRequest({
            mobile,
            password,
        });
    }

    

    @action
    async validateBrand({ tenantUid, storeUid }: UserInfoParam) {
        return await userRequest({
            tenantUid,
            storeUid
        });
    }

    @action
    async mobileMessageRequest({ mobile }: MobileMessageParam) {
        return await mobileMessageRequest({
            mobile,
        });
    }

    @action
    async loginRequestByMobile({ mobile, verifyCode }: LoginByMobileParam) {
        return await loginRequestByMobile({
            mobile,
            verifyCode,
        });
    }

    @action
    async mobileForgetRequest({ mobile, verifyCode }: MobileForgetParam) {
        return await mobileForgetRequest({
            mobile,
            verifyCode,
        });
    }

    @action
    async passwordResetRequest({
        mobileNo,
        password,
        confirmPassword,
    }: PasswordResetParam) {
        return await passwordResetRequest({
            mobileNo,
            password,
            confirmPassword,
        });
    }

    @action
    async logout() {
        return await logout();
    }

    @action
    setCache() {
        this.cache = 'dddddddd';
    }

    // @action
    // setIsAuto(isAuto: boolean) {
    // 	this.isAuto = isAuto;
    // }

    // @computed
    // get getIsAuto() {
    // 	return this.isAuto;
    // }

    // @computed
    // get getUserInfo() {
    // 	return this.user;
    // }

    // testFun() {
    // 	console.log('this is test');
    // }
}
export default UserStore;

