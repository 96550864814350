import React, { useEffect, useState, useMemo, useRef } from 'react'
import D from 'src/components/AntD'
import { Form } from 'antd'
import _ from 'underscore'

import { CustomerMetadata, AttributeMetadata } from 'src/views/taskManagement/customerMetadata'

import { getCustomerDicts } from 'src/api/screenCondition'

interface Iprops {
    customerMetadata: CustomerMetadata
    antForm: any
    index: number
    fieldIndex: number
    refresh: any
    isDetail: boolean
    i: any
}

const StringComponent = ({ customerMetadata, antForm, index, i, fieldIndex, refresh, isDetail }: Iprops) => {
    const [options, setOptions] = useState([])
    const [loadings, setLoadings] = useState<boolean>(false)
    let timeout: ReturnType<typeof setTimeout> | null;
    const [page] = useState({
        pageNum: 1,
        pageSize: 200
    })
    const queryOptions = async (attributeMetadata: AttributeMetadata, value?: string) => {
        const { data, code } = await getCustomerDicts({
            uniqueId: attributeMetadata.uniqueId,
            search: value || null,
            page
        })
        const optionData = data.map((item: any) => ({
            label: item.display,
            value: item.value
        }))
        if (code === '00000') {
            setLoadings(true)
            setOptions(optionData)
        }
    }

    useEffect(() => {
        const attributeMetadata = customerMetadata.findAttributeMetadataByIndex(index, i)
        if (attributeMetadata.dictFlag === 1) {
            (async () => {
                await queryOptions(attributeMetadata)
            })()
        }
    }, [customerMetadata])

    const getSubOperatorComponent = () => {
        const attributeMetadata = customerMetadata.findAttributeMetadataByIndex(index, i)
        const operatorInfo = _.findWhere(attributeMetadata.operators || [], { operator: attributeMetadata.operatorValue })
        if (attributeMetadata.dictFlag === 1) {
            if (operatorInfo?.valueType === 'MULTI') {
                return <D.Select mode="multiple" value={attributeMetadata.extra && attributeMetadata.extra.value} onChange={(value) => {
                    if (!value.length) queryOptions(attributeMetadata)
                    const extra = {
                        value
                    }
                    const formData = customerMetadata.updateExtraValue(extra, index, i).getCustomerAttributeData()
                    antForm.getFieldValue('metadataForm')[index].metadataInnerForm[i] = formData[index][i]
                    // 切换选项
                    if (Array.isArray(antForm.getFieldValue('metadataForm')[index].metadataInnerForm[i].subOperatorValue)) {
                        antForm.getFieldValue('metadataForm')[index].metadataInnerForm[i].subOperatorValue = value
                    }
                    antForm.setFieldsValue({
                        metadataForm: antForm.getFieldValue('metadataForm')
                    })
                   
                }} onSearch={(value: string) => {
                    if (timeout) {
                        clearTimeout(timeout);
                        timeout = null;
                    }
                    timeout = setTimeout(() => {queryOptions(attributeMetadata, value)}, 800)}
                }  placeholder="请选择" options={options} style={{ width: 200 }}  disabled={isDetail} loading={!loadings && !isDetail}/>
            } else if (operatorInfo?.valueType === 'SINGLE') {
                return <D.Select value={attributeMetadata.extra && attributeMetadata.extra.value} onChange={(value) => {
                    const extra = {
                        value
                    }
                    const formData = customerMetadata.updateExtraValue(extra, index, i).getCustomerAttributeData()
                    antForm.getFieldValue('metadataForm')[index].metadataInnerForm[i] = formData[index][i]
                    antForm.setFieldsValue({
                        metadataForm: antForm.getFieldValue('metadataForm')
                    })
                }} onSearch={(value: string) => {
                    if (timeout) {
                        clearTimeout(timeout);
                        timeout = null;
                    }
                    timeout = setTimeout(() => {queryOptions(attributeMetadata, value)}, 800)}
                }  placeholder="请选择"  disabled={isDetail} options={options}  loading={!loadings && !isDetail} style={{ width: 200 }}/>
            }
            return null
        } else {
            if (operatorInfo?.valueType === 'MULTI') {
                return <D.Select mode='tags' value={attributeMetadata.extra && attributeMetadata.extra.value} onChange={(value) => {
                    const extra = {
                        value
                    }
                    const formData = customerMetadata.updateExtraValue(extra, index, i).getCustomerAttributeData()
                    antForm.getFieldValue('metadataForm')[index].metadataInnerForm[i] = formData[index][i]
                    // 切换选项
                    if (Array.isArray(antForm.getFieldValue('metadataForm')[index].metadataInnerForm[i].subOperatorValue)) {
                        antForm.getFieldValue('metadataForm')[index].metadataInnerForm[i].subOperatorValue = value
                    }
                    antForm.setFieldsValue({
                        metadataForm: antForm.getFieldValue('metadataForm')
                    })
                }} placeholder="请输入"  disabled={isDetail}  open={false} style={{ width: 200 }} />
            } else if (operatorInfo?.valueType === 'SINGLE') {
                return <D.Input value={attributeMetadata.extra && attributeMetadata.extra.value} onChange={({ target: { value } }) => {
                    const extra = {
                        value
                    }
                    const formData = customerMetadata.updateExtraValue(extra, index, i).getCustomerAttributeData()
                    antForm.getFieldValue('metadataForm')[index].metadataInnerForm[i] = formData[index][i]
                    antForm.setFieldsValue({
                        property: antForm.getFieldValue('property')
                    })
                }} placeholder="请输入"  disabled={isDetail} style={{ width: 200 }}/>
            }
            return null
        }
    }
    
    const formList = [
        {
            label: '',
            name: 'queryName',
            children: <D.Select options={customerMetadata.getGroupOptions()} onChange={(value) => {
                const formData = customerMetadata.updateQueryNameByIndex(value as string, index, i).getCustomerAttributeData()
                antForm.getFieldValue('metadataForm')[index].metadataInnerForm[i] = formData[index][i]
                if (typeof( antForm.getFieldValue('metadataForm')[index].metadataInnerForm[i].subOperatorValue) === 'undefined') {
                    antForm.getFieldValue('metadataForm')[index].metadataInnerForm[i].subOperatorValue = []
                }
                antForm.setFieldsValue({
                    metadataForm: antForm.getFieldValue('metadataForm'),
                })
                const attribute = customerMetadata.findAttributeMetadataByIndex(index, i)
                if (attribute.dictFlag === 1) {
                    queryOptions(attribute)
                }
                refresh()
            }} allowClear={false} placeholder="请选择" style={{ width: 200 }} disabled={isDetail}/>,
        },
        {
            label: '',
            name: 'operatorValue',
            children: <D.Select options={customerMetadata.getOperatorOption(index, i)} onChange={(value) => {
                const formData = customerMetadata.updateOperatorValueByIndex(value as string, index, i).getCustomerAttributeData()
                antForm.getFieldValue('metadataForm')[index].metadataInnerForm[i] = formData[index][i]
                antForm.setFieldsValue({
                    metadataForm: antForm.getFieldValue('metadataForm')
                })

            }} allowClear={false} placeholder="请选择" style={{ width: 200 }} disabled={isDetail}/>,
        },
        {
            label: '',
            name: 'subOperatorValue',
            children: getSubOperatorComponent(),
        },
    ]

    return (
        formList.map((f, i) => (
            f.children && <Form.Item key={f.name} {...f} name={[fieldIndex, f.name]} />
        ))
    )
}
StringComponent.indentify = 'STRING'

export default StringComponent