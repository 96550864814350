import StringComponent from './StringComponent'
import NumberComponent from './NumberComponent'
import DateComponent from './DateComponent'
import DateTimeComponent from './DateTimeComponent'
import BooleanComponent from './BooleanComponent'
import ArrayComponent from './ArrayComponent'

const metadataComponents = new Map();

const registerComponent = (metadataComponent: any) => {
    const type = metadataComponent.indentify;
    if (type !== null) {
    	metadataComponents.set(type, metadataComponent);
    }
}

export { metadataComponents };
export default function register() {
    registerComponent(StringComponent)
    registerComponent(NumberComponent)
    registerComponent(DateComponent)
    registerComponent(DateTimeComponent)
    registerComponent(BooleanComponent)
    registerComponent(ArrayComponent)
    // registerComponent(RelativeFixTimeComponent);
    // registerComponent(DistrictComponent);
    // registerComponent(NumberComponent);
    // registerComponent(EnumComponent);
    // registerComponent(PeriodDateComponent);
    // registerComponent(PercentComponent);
}