import React, { useState, useRef } from 'react'
import { DatePicker } from 'antd'
import 'moment/locale/zh-cn'
import locale from 'antd/lib/date-picker/locale/zh_CN'
import moment, { Moment } from 'moment'
// import { RangePickerProps } from "antd/es/date-picker";
import { cloneDeep } from 'lodash'
import { RangePickerProps, DatePickerProps } from 'antd/lib/date-picker'
// import PickerProps from "antd/lib/date-picker"
import type { Dayjs } from 'dayjs'

const { RangePicker } = DatePicker
interface IProps {
    prefixCls?: string
    value?: any
    onChange?: (date: [Dayjs | null, Dayjs | null]) => void
    picker?: any
    showTime?: boolean
    limitDay?: number
    endOfDay?: string
    isShowRanges?: boolean
}

const RangeDatePicker: React.FC<IProps & RangePickerProps> = ({
    value, // 日期
    picker = 'date',
    onChange, // 时间发生变化的回调，发生在用户选择时间时
    showTime = false, // 是否增加时间选择功能（时分秒）
    limitDay = 0, // 只能选择limitDay天数范围之内的日期，默认无限制
    endOfDay = moment().endOf('day'), // endOfDay以后的时间不能选择，默认今天
    isShowRanges = false,
    ...rest // 其他参数
}) => {
    const ref = useRef<HTMLDivElement>(null)
    const startOfDay = moment().subtract(limitDay, 'days').endOf('day') // 今天之前limitDay天
    // const {value, setValue} = useState(null);
    const [selectDate, setSelectDate] = useState([startOfDay, endOfDay])
    const [selectvalue, setSelectValue] = useState([startOfDay, endOfDay])

    // 不可选择的日期
    // const disabledTaskDate = (current: Moment) => {
    //     let date: any = cloneDeep(selectDate)
    //     if (selectDate && selectDate?.length > 0 && limitDay > 0) {
    //         // 控制只能选择limitDay天数范围之内的日期
    //         let startOfStage = date?.[1]
    //             ?.subtract(limitDay, 'days')
    //             .endOf('day')
    //         let endOfStage = date?.[0]?.add(limitDay, 'days').endOf('day')
    //         if (endOfStage > endOfDay) {
    //             endOfStage = endOfDay
    //         }
    //         return current < startOfStage || current > endOfStage
    //     } else {
    //         // endOfDay以后的时间不能选择
    //         return current > endOfDay
    //     }
    // }

    // 待选日期发生变化的回调
    const onDateChange = (dates: string | any[]) => {
        if (!dates || !dates?.length) return
        setSelectDate([cloneDeep(dates?.[0]), cloneDeep(dates?.[1])])
    }

    // 弹出日历和关闭日历的回调
    const onDateOpenChange = (open: any) => {
        if (open) {
            if (selectvalue && selectvalue?.length) return
            setSelectDate([])
        }
    }

    // 时间发生变化的回调
    const onPickerChange = (value: any) => {
        setSelectValue(value)
        // onChange(value)
    }

    // 选择日期范围
    // const getRange = () => {
    //     console.log('dasdas')
    // const toDayRange = {
    //     今天: [moment().startOf('day'), moment().endOf('day')],
    // }
    // // 本周
    // const thisWeekRange =
    //     moment().startOf('week') <= endOfDay
    //         ? { 本周: [moment().startOf('week'), endOfDay] }
    //         : {}
    // // 本月
    // const thisMonthRange =
    //     moment().startOf('month') <= endOfDay
    //         ? { 本月: [moment().startOf('month'), endOfDay] }
    //         : {}
    // console.log({ ...toDayRange })
    // return {
    //     // 今天
    //     // thisWeekRange,
    //     // thisMonthRange,
    // }
    // return isShowRanges
    //     ? {
    //           today: [moment().startOf('day'), moment().endOf('day')],
    //           week: [moment().startOf('week'), moment().endOf('week')],
    //           month: [moment().startOf('month'), moment().endOf('month')],
    //       }
    //     : {}
    // }

    return (
        <RangePicker
            value={value}
            picker={picker}
            onChange={onPickerChange} // 时间发生变化的回调
            // disabledDate={disabledTaskDate}
            // ranges={getRange()}
            ranges={{
                今日: [moment(), moment()],
                本周内: [moment().startOf('week'), moment().endOf('week')],
                近一周: [moment().subtract(1, 'week'), moment()],
                本月内: [moment().startOf('month'), moment().endOf('month')],
            }}
            {...rest}
        />
    )
}

export default RangeDatePicker
