import { UserStore } from './user/userStore';
import {CommonStore} from './common/commonStore'
import { TaskManagerStore } from './taskManage'
import { CustomersStore } from './customers'
import { observable, action, makeAutoObservable } from 'mobx';


class RootStore {
    @observable userStore: UserStore
    @observable commonStore: CommonStore
    @observable taskManagerStore: TaskManagerStore
    @observable customersStore: CustomersStore

    constructor() {
        makeAutoObservable(this);
        const local = sessionStorage.getItem('cache') || '{}';
        const cache = JSON.parse(local);
        this.userStore = Object.assign(new UserStore(), cache.userStore);
        this.commonStore = Object.assign(new CommonStore(), cache.commonStore);
        this.taskManagerStore = Object.assign(new TaskManagerStore(), cache.taskManageStore);
        this.customersStore = Object.assign(new CustomersStore(), cache.customersStore);
    }
}

export default new RootStore()