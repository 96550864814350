import React, { useEffect, useState } from 'react'
import { observer, inject } from 'mobx-react'
import { Navigate } from 'react-router-dom'
import store from 'src/store'
import Nopermission from '../views/Nopermission'
import { routes } from 'src/routers'
import { userInfoCheck } from 'src/api/login'
import { AnyObject } from 'yup/lib/types'
import { stroage } from 'src/utils/index'
import _ from 'underscore'
import L from 'lodash'

const enum Status {
    INIT = 'init',
    UNVERIFIED = 'unverified',
    NONPERMISSIVE = 'nonpermissive',
    VERIFY = 'verify',
}

interface IProps {
    children?: React.ReactNode | any
}

export const Authorization: React.FC<IProps> = ({ children }) => {
    const { getUser, user, setMenuTree } = store.userStore
    const [authStatus, setAuthStatus] = useState<Status>(Status.INIT)
    const getMenu = (routes: any, menus: any) => {
        const data = routes.map((item: any) => {
            if (item.path === '/') {
                const arr: any = []

                item.children.forEach((child: any) => {
                    if (child.path === '') {
                        arr.push(child)
                    } else {
                        const current = _.findWhere(menus, { permissionAction: child.path })
                        if (current) {
                            const newChildren =  child?.children && child.children?.filter((f: any) => (_.findWhere(menus, { permissionAction: f.path }) || f.hidden) && f)

                            const menuObj = {
                                ...child,
                                children: newChildren
                            }
                            arr.push(menuObj)
                            // if (newChildren && newChildren.filter((f: any) => !f.hidden && f).length) {
                            //     arr.push(menuObj)
                            // }
                        }
                    }
                })
                item.children = arr
            }
            return item
        })
        return data
    }

    const checkUserVerify = async () => {
        return new Promise((resolve, reject) => {
            try {
                userInfoCheck().then((info: AnyObject) => {
                    const userAssign = Object.assign(user, info.data)
                    stroage.local.set('userInfo', JSON.stringify(userAssign))
                    getUser(userAssign)

                    const menus = info.data.permissionItemList.filter(
                        (p: any) => p.permissionType === 'MENU'
                    ) || []
                    const routesData = L.cloneDeep(routes)
                    // 本地新增页面把权限先暂时注销掉
                    const tree = getMenu(routesData, menus)
                    console.log(tree, 'tree')
                    setMenuTree(tree)
                    // setMenuTree(routesData)
                    resolve('SUCCESS')
                })
            } catch (error) {
                console.log('核对用户信息异常:' + error)
                reject(new Error('核对用户信息异常:' + error))
            }
        })
    }

    useEffect(() => {
        const authCode = stroage.local.get('4S_token')
        if (!authCode) {
            setAuthStatus(Status.UNVERIFIED)
            return
        }
        checkUserVerify().then((data: any) => {
            if (data !== 'SUCCESS') {
                setAuthStatus(Status.UNVERIFIED)
                return
            }
            setAuthStatus(Status.VERIFY)
        })
    }, [])

    return (
        <div>
            {
                {
                    [Status.INIT]: null,
                    [Status.UNVERIFIED]: <Navigate to="/login" />,
                    [Status.NONPERMISSIVE]: <Nopermission />,
                    [Status.VERIFY]: children,
                }[authStatus]
            }
        </div>
    )
}
export default inject('userStore')(observer(Authorization))
