import { Button, Form, Spin, Input } from 'antd'
import React, { useState } from 'react'
import PageDialog from 'src/components/Modal'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import D from 'src/components/AntD'
import styles from './index.module.less'
import SvgIcon from 'src/components/SvgIcon/SvgIcon'
import { observer, inject } from 'mobx-react'
import store from 'src/store'
import { resetPassword } from 'src/api/login'
import { useNavigate } from 'react-router-dom'
import { stroage } from 'src/utils/index'

interface DialogResetPasswordProps {
    open: boolean
    form?: FieldItem
    onClose: () => void
}

interface FieldItem {
    password: string
    newPassword: string
    confirmPassword: string
}

const initialForm: FieldItem = {
    newPassword: '',
    confirmPassword: '',
    password: '',
}

const resetStyle = {
    padding: 0,
    marginTop: '60px',
}

interface AccountFormType {
    password: string
    newPassword: string
    confirmPassword: string
}

// const initialValues: AccountFormType = {
// 	mobile: '',
// 	verifyCode: ''
// }
// const pwdInitialValues: PasswordFormType = {
// 	password: '',
// 	confirmPassword: ''
// }

const DialogResetPassword: React.FC<DialogResetPasswordProps> = ({
    open,
    onClose,
    form = initialForm,
}) => {
    const [loading, setLoading] = useState(false)
    const [antForm] = Form.useForm()
    const navigate = useNavigate()
    const { user, clearOut } = store.userStore

    const validationSchema = Yup.object({
        password: Yup.string().required('请输入当前密码'),
        newPassword: Yup.string().matches(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{6,20}$/,'必须包含大写字母、小写字母、数字,超度超过6位').required('请输入密码'),
        confirmPassword: Yup.mixed()
            .test('empty', '请输入确认密码', (v) => !!v)
            .test(
                'confirm',
                '两次密码不一致',
                (v, context) => v === context.parent.newPassword
            ),
    })

    const formik = useFormik({
        initialValues: form,
        validationSchema,
        onSubmit: async (values) => {
            console.log('values', values)
            postForm({ mobileNo: user.mobile, ...values })
        },
    })

    const postForm = async (params: any) => {
        console.log('params', params)
        const { password, newPassword } = params
        const passwordParams = {
            oldPassword: password,
            newPassword,
        }
        try {
            setLoading(true)
            await resetPassword({ ...passwordParams })
            store.commonStore.setNotify({
                type: 'success',
                message: '修改成功，请重新登录',
                open: true,
            })
            handleClose()
            setTimeout(() => {
                clearOut()
                stroage.local.clear()
                navigate('/login')
            }, 2000)
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    const formSubmit = () => {
        antForm.submit()
    }

    const handleClose = () => {
        formik.resetForm()
        onClose()
    }

    const getValidProps = (k: keyof FieldItem) => ({
        validateStatus: formik.touched[k] && formik.errors[k] ? 'error' : '',
        help: formik.touched[k] && formik.errors[k] ? formik.errors[k] : null,
    })

    let FormList: any[] = [
        {
            label: '当前手机号',
            key: 'phone',
            children: (
                <span style={{ marginLeft: '10px', color: '#516F90' }}>
                    {stroage.local.get('4S_phone')}
                </span>
            ),
        },
        {
            isRequired: true,
            label: '旧密码',
            key: 'password',
            ...getValidProps('password'),
            children: (
                <D.Input.Password
                    {...formik.getFieldProps('password')}
                    placeholder="请输入当前密码"
                    type="password"
                />
            ),
        },
        {
            isRequired: true,
            label: '新密码',
            key: 'newPassword',
            ...getValidProps('newPassword'),
            children: (
                <D.Input.Password
                    {...formik.getFieldProps('newPassword')}
                    placeholder="请输入新密码"
                />
            ),
        },
        {
            isRequired: true,
            label: '确认密码',
            key: 'confirmPassword',
            ...getValidProps('confirmPassword'),
            children: (
                <D.Input.Password
                    {...formik.getFieldProps('confirmPassword')}
                    placeholder="请输入确认密码"
                />
            ),
        },
    ]

    const ActionNodes = (
        <>
            <Button onClick={handleClose}>取消</Button>
            <Button type="primary" disabled={loading} onClick={formSubmit}>
                确定
            </Button>
        </>
    )

    return (
        <PageDialog
            open={open}
            width={600}
            title="修改密码"
            action={ActionNodes}
            onClose={handleClose}
            destroyOnClose
            closeIcon={<SvgIcon name="close-icon" />}
        >
            <Spin spinning={loading}>
                <Form
                    className="dialog-form"
                    form={antForm}
                    labelCol={{ span: 5 }}
                    wrapperCol={{ span: 16 }}
                    onFinish={formik.handleSubmit}
                    autoComplete="off"
                >
                    {FormList.map((f, i) => {
                        const { visibility, isRequired, ...props } = f
                        if (visibility && !visibility()) return undefined
                        return (
                            <Form.Item
                                key={f.label}
                                {...props}
                                className={isRequired ? 'bc-required' : ''}
                            />
                        )
                    })}
                </Form>
            </Spin>
        </PageDialog>
    )
}

export default inject('userStore')(observer(DialogResetPassword))
