import { Menu } from 'antd'
import React, { useCallback, useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import styles from '../index.module.less'
import SvgIcon from 'src/components/SvgIcon/SvgIcon'
import { CaretDownOutlined,AppstoreOutlined, CaretUpOutlined } from '@ant-design/icons'

interface SMenuProps {
    sideMenu: any[]
    activeMenu: string[]
    collapsed: boolean
}

const SMenu: React.FC<SMenuProps> = ({ sideMenu, activeMenu, collapsed }) => {
    const [openKeys, setOpenKeys] = useState<React.Key[]>([])
    const Icon = (name: string) => <SvgIcon name={name} />

    const getSubMenu = useCallback((list: any[]) => {
        return list.map((item) => {
            if (item.hidden) return null
            const hasC = !item.children
            // const menuUrl = item.fullpath
            // const menuName = item.meta.title
            // const { menuUrl, menuName } = item.menu
            const { path } = item
            const { meta } = item
            return hasC ? (
                <Menu.Item
                    key={path}
                    icon={meta && meta.icon ? Icon(meta.icon) : null}
                >
                    <NavLink to={path}>{meta.title}</NavLink>
                </Menu.Item>
            ) : (
                <Menu.SubMenu
                    key={path}
                    title={meta.title}
                    icon={meta && meta.icon ? Icon(meta.icon) : null}
                    popupClassName="side-menu-popup"
                    
                >
                    {getSubMenu(item.children)}
                </Menu.SubMenu>
            )
        })
    }, [])

    useEffect(() => {
        setOpenKeys([...activeMenu])
    }, [activeMenu])

    return (
        <Menu
            mode="inline"
            inlineIndent={25}
            expandIcon={<SvgIcon name="expand" />}
            // inlineCollapsed={collapsed}
            style={collapsed ? {} : { borderRight: 'none' }}
            selectedKeys={activeMenu}
            openKeys={openKeys as string[]}
            onOpenChange={(keys) => setOpenKeys([...keys])}
        >
            {getSubMenu(sideMenu)}
        </Menu>
    )
}

export default React.memo(SMenu)
