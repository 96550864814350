import { Layout, Modal, Popover, Tooltip } from 'antd'
import React, { useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import styles from '../index.module.less'
import styled from 'styled-components'
import { observer } from 'mobx-react'

import { stroage } from 'src/utils/index'
import { useStore } from 'src/hooks/useStores'
import { logout } from 'src/api/login'

const { Header } = Layout
import SvgIcon from 'src/components/SvgIcon/SvgIcon'
import DialogResetPassword from 'src/views/login/DialogResetPassword'

const UserOpera = styled.div`
    li {
        list-style: none;
        height: 40px;
        line-height: 40px;
        border-bottom: 1px solid #f0f0f0;
        text-align: center;
        color: #33475b;
        cursor: pointer;
        &:hover {
            color: #2878ff;
        }
    }
`

const HeaderNav: React.FC = () => {
    const userStore = useStore('userStore')
    const navigate = useNavigate()
    const [open, setOpen] = useState(false)

    const handleLogout = () => {
        const confirmCB = async () => {
            try {
                await logout()
                userStore.clearOut()
                stroage.local.clear()
                navigate('/login')
            } catch (error) {
                console.log(error)
            }
        }
        Modal.confirm({
            centered: true,
            content: '是否确认退出登录？',
            title: '退出提示',
            onOk: confirmCB,
            okText: '确认',
            cancelText: '取消',
        })
    }

    const UserOperation = () => (
        <UserOpera>
            <ul>
                <li onClick={() => setOpen(true)}>修改密码</li>
                <li onClick={handleLogout}>退出登录</li>
            </ul>
        </UserOpera>
    )

    return (
        <Header className={styles['layout-header']}>
            <div className={styles['logo-wrapper']}>
                <NavLink to="/">
                    <SvgIcon name="newlogo" />
                    <span>超级站长</span>
                </NavLink>
            </div>
            <div className={styles.infomation}>
                <div className={styles.brandName}>【 {userStore.user.storeName} 】</div>
                <div className={styles.account}>{userStore.user.username}</div>
                <div className={styles.avatar}>
                    <SvgIcon name="avatar" />
                </div>
                {/* dialog */}
                <div className={styles.logout}>
                    <Popover
                        content={<UserOperation />}
                        overlayStyle={{ width: '130px' }}
                        overlayClassName="nopadding"
                        placement="bottomLeft"
                        trigger="hover"
                        arrowPointAtCenter
                    >
                        <div>
                            <SvgIcon name="logout" />
                        </div>
                    </Popover>
                </div>
            </div>
            <DialogResetPassword open={open} onClose={() => setOpen(false)} />
        </Header>
    )
}
export default observer(HeaderNav)
